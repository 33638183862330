// Sanitize the request and the response
export const sanitizeNetworkData = (sensitiveFields) => (data) => {
  // Fields that need to be sanitized
  const sanitizeObject = (obj) => {
    const sanitizedObj = { ...obj };

    sensitiveFields.forEach((field) => {
      if (sanitizedObj[field]) {
        sanitizedObj[field] = '***';
      }
    });

    return sanitizedObj;
  };

  if (data.body) {
    try {
      const body = JSON.parse(data.body);
      const sanitizedBody = sanitizeObject(body);
      data.body = JSON.stringify(sanitizedBody);
    } catch {}
  }

  return data;
};

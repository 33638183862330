import React from 'react';

export const SatelliteInternet = (props) => {
  return (
    <svg width='125px' viewBox='0 0 46 46' {...props}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          transform='translate(1.000000, 1.000000)'
          fill={props.fill || '#DDD'}
        >
          <path
            d='M22,44 C9.8497355,44 0,34.1502645 0,22 C0,9.8497355 9.8497355,0 22,0 C34.1502645,0 44,9.8497355 44,22 C44,34.1502645 34.1502645,44 22,44 Z M22,41.9692308 C33.0287016,41.9692308 41.9692308,33.0287016 41.9692308,22 C41.9692308,10.9712984 33.0287016,2.03076923 22,2.03076923 C10.9712984,2.03076923 2.03076923,10.9712984 2.03076923,22 C2.03076923,33.0287016 10.9712984,41.9692308 22,41.9692308 Z'
            fillRule='nonzero'
          ></path>
          <g transform='translate(15.500000, 20.000000) rotate(45.000000) translate(-15.500000, -20.000000) translate(6.000000, 10.000000)'>
            <path d='M17.9705317,9.03872119 L17.9705317,9.91672855 C17.9705317,11.8218926 16.4260911,13.3663333 14.520927,13.3663333 C12.6157629,13.3663333 11.0713223,11.8218926 11.0713223,9.91672855 L11.0713223,7.91671182 L9.22610188,7.91671182 L9.22610188,5.49465784 L11.0713223,5.49465784 L11.0713223,3.49464111 C11.0713223,1.58947703 12.6157629,0.045036393 14.520927,0.045036393 C15.1944181,0.045036393 15.8228302,0.238042095 16.3538702,0.571760164 C14.3282297,2.71353169 13.093682,5.57950829 12.9383968,8.51025239 C12.8557343,10.070368 14.1750632,11.2177559 15.7115386,10.9732274 C16.7623843,10.8059864 17.6447702,10.0263668 17.9705317,9.03872119 Z'></path>
            <path
              d='M1.84522038,5.30479466 L1.84522038,7.72684864 L7.38088151,7.72684864 L7.38088151,5.30479466 L1.84522038,5.30479466 Z M1.23014692,3.48825418 L7.99595497,3.48825418 C8.67534635,3.48825418 9.22610188,4.03044943 9.22610188,4.69928117 L9.22610188,8.33236214 C9.22610188,9.00119387 8.67534635,9.54338912 7.99595497,9.54338912 L1.23014692,9.54338912 C0.550755535,9.54338912 0,9.00119387 0,8.33236214 L0,4.69928117 C0,4.03044943 0.550755535,3.48825418 1.23014692,3.48825418 Z'
              fillRule='nonzero'
            ></path>
            <polygon points='3.69044075 9.54338912 3.69044075 3.48825418 5.53566113 3.48825418 5.53566113 9.54338912'></polygon>
            <path
              d='M18.7597405,16.5067943 C18.7597405,17.9208866 16.9583585,18.3754793 14.4542263,18.3754793 C11.9500941,18.3754793 10.1487121,17.9208866 10.1487121,16.5067943 C10.1487121,14.1658832 12.0763564,12.2681998 14.4542263,12.2681998 C16.8320961,12.2681998 18.7597405,14.1658832 18.7597405,16.5067943 Z M14.4542263,14.0847403 C13.1879608,14.0847403 12.1451254,15.0264893 12.0089859,16.2373412 C12.0982736,16.2735307 12.2160834,16.3115755 12.3575762,16.3472823 C12.8913132,16.481975 13.6537605,16.5589388 14.4542263,16.5589388 C15.2546921,16.5589388 16.0171394,16.481975 16.5508764,16.3472823 C16.6923692,16.3115755 16.810179,16.2735307 16.8994667,16.2373412 C16.7633272,15.0264893 15.7204917,14.0847403 14.4542263,14.0847403 Z'
              fillRule='nonzero'
            ></path>
            <rect
              x='13.5316161'
              y='17.4150646'
              width='1.84522038'
              height='2.42205398'
              rx='0.922610188'
            ></rect>
          </g>
          <g transform='translate(20.000000, 20.000000)'>
            <path
              d='M8.16761364,12.8968254 C7.06496179,12.8968254 6.17108586,12.0476922 6.17108586,11.0002334 C6.17108586,9.95277461 7.06496179,9.10364146 8.16761364,9.10364146 C9.27026548,9.10364146 10.1641414,9.95277461 10.1641414,11.0002334 C10.1641414,12.0476922 9.27026548,12.8968254 8.16761364,12.8968254 Z'
              fillRule='nonzero'
            ></path>
            <path
              d='M3.31998225,4.38354034 C3.7758594,4.049375 4.43086466,4.11868069 4.79731715,4.54445865 C5.56795193,5.43985252 6.68997968,5.9475538 7.8661579,5.93357332 C8.93493154,5.92086949 10.0269661,5.41261262 10.8416792,4.54474603 C11.2264717,4.13484891 11.8836871,4.09141411 12.3245129,4.44386439 C12.7845557,4.81167905 12.8356953,5.47071015 12.4337919,5.89883449 C11.2258488,7.18558619 9.57285788,7.94527655 7.89285314,7.96524569 C6.06526838,7.98696903 4.32657818,7.20440646 3.14575345,5.83241645 C2.76317506,5.38790193 2.84466692,4.73195418 3.31998225,4.38354034 Z'
              fillRule='nonzero'
              transform='translate(7.804609, 6.069094) rotate(180.000000) translate(-7.804609, -6.069094) '
            ></path>
            <path
              d='M0.444675877,0.211538284 C-0.0636044291,0.557332494 -0.15014616,1.2038517 0.257622194,1.64357541 C2.21842441,3.75803873 5.10264252,4.96390464 8.13414469,4.93046145 C10.9168478,4.89976298 13.6676204,3.72400508 15.6828027,1.73166501 C16.1114032,1.30792273 16.0571045,0.65848264 15.5654843,0.293677114 C15.0896169,-0.0594390938 14.3778245,-0.0157790416 13.9631579,0.39418733 C12.3711365,1.968163 10.2262725,2.8918391 8.10520758,2.91523845 C5.77873295,2.94090386 3.56061547,2.01059892 2.04095856,0.371851896 C1.64629571,-0.0537392796 0.936991897,-0.123395073 0.444675877,0.211538284 Z'
              transform='translate(7.986111, 2.465570) rotate(180.000000) translate(-7.986111, -2.465570) '
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

import { createContext, useState } from 'react';

export const CallCenterContext = createContext();

export function CallCenterProvider({ children }) {
  const [callCenterOpen, setCallCenterOpen] = useState(true);

  return (
    <CallCenterContext.Provider value={callCenterOpen}>
      {children}
    </CallCenterContext.Provider>
  );
}

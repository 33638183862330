import React from 'react';

export const SatelliteRebrand = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      {...props}
    >
      <path
        d='M19.0627 25.4477L14.6018 20.9839C13.8135 20.1956 12.8238 20.5606 12.2341 20.7766L8.44761 22.1663L1.45264 29.1613C0.7111 29.9028 0.7111 31.1114 1.45264 31.8559L8.4155 38.8188L8.43301 38.8013C8.76291 39.0377 9.1512 39.1545 9.53948 39.1545C10.027 39.1545 10.5146 38.9677 10.8883 38.5969L17.8511 31.634L19.2758 27.8096C19.3926 27.4972 19.5502 27.0709 19.5502 26.6213C19.5502 26.163 19.3867 25.7689 19.0627 25.4477ZM15.8075 31.1377L9.61831 37.3269C9.57451 37.3707 9.50445 37.3707 9.46358 37.3269L6.73098 34.5943C9.87814 31.4881 11.986 29.3861 13.0107 28.338L15.8075 31.1377ZM11.7437 27.0709C10.5438 28.2942 7.71191 31.1027 5.46103 33.3244L2.71967 30.583C2.70216 30.5655 2.69048 30.5363 2.69048 30.5071C2.69048 30.4779 2.70216 30.4487 2.71967 30.4283L8.9118 24.2362L11.7437 27.0709ZM17.7519 26.6739C17.7343 26.7965 17.6263 27.0885 17.5884 27.1936L16.7242 29.5145L10.5263 23.3136L12.8793 22.4495C12.9786 22.4144 13.2559 22.3093 13.3756 22.2947L17.7519 26.6739Z'
        fill='#0096FA'
      />
      <path
        d='M38.544 8.24636L31.803 1.50537C31.444 1.14628 30.9652 0.947754 30.4572 0.947754C29.9492 0.947754 29.4704 1.14628 29.1113 1.50537L22.1134 8.50035L20.715 12.3131C20.5019 12.8941 20.1457 13.8663 20.934 14.6545L25.3949 19.1184C25.7627 19.4862 26.1714 19.603 26.5626 19.603C27.0181 19.603 27.4502 19.4424 27.7684 19.3227L31.5812 17.9039L38.544 10.941C38.9031 10.5819 39.0987 10.1031 39.0987 9.59514C39.1016 9.08132 38.9031 8.60545 38.544 8.24636ZM24.1891 8.96454L30.3784 2.77241C30.4192 2.73153 30.4922 2.73153 30.5331 2.77241L33.2861 5.52544C32.1388 6.69322 29.4062 9.4083 27.0035 11.7789L24.1891 8.96454ZM37.277 9.67105L31.0849 15.8603L28.2764 13.0518C30.5214 10.8359 33.289 8.09455 34.5561 6.7954L37.277 9.51632C37.3179 9.55719 37.3179 9.62726 37.277 9.67105ZM29.4617 16.777L27.129 17.644C27.0268 17.682 26.7407 17.79 26.621 17.8046L22.2448 13.4255C22.2623 13.3058 22.3557 13.0459 22.3995 12.9291L23.2637 10.5732L29.4617 16.777Z'
        fill='#0096FA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.845 24.4843C19.013 23.6523 15.2936 19.9329 15.2936 19.9329C14.8382 19.4775 14.6046 18.8498 14.6484 18.2075L14.7331 16.9755C14.771 16.4412 14.9987 15.9362 15.3783 15.5566C15.7578 15.1771 16.2629 14.9465 16.7971 14.9115L18.0291 14.8268C18.6714 14.783 19.2991 15.0195 19.7545 15.472L24.3964 20.1139C24.8518 20.5693 25.0854 21.197 25.0416 21.8393L24.9569 23.0713C24.919 23.6056 24.6913 24.1106 24.3117 24.4901C23.9322 24.8697 23.4272 25.1003 22.8929 25.1353L21.766 25.2112C21.766 25.2112 20.6741 25.3163 19.845 24.4843Z'
        fill='#0096FA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.8134 22.9282C30.866 27.2665 27.3977 30.7348 23.0594 30.6823C22.4697 30.6764 21.985 31.1465 21.9792 31.7391C21.9734 32.3288 22.4434 32.8135 23.036 32.8222C28.5771 32.8894 33.0205 28.446 32.9534 22.9049C32.9475 22.3152 32.4629 21.8422 31.8702 21.848C31.2805 21.8539 30.8076 22.3356 30.8134 22.9282ZM26.9393 22.8786C26.9656 25.0769 25.2139 26.8315 23.0127 26.8053C22.4229 26.7994 21.9383 27.2694 21.9296 27.8621C21.9237 28.4518 22.3937 28.9365 22.9864 28.9423C26.3875 28.9832 29.1172 26.2535 29.0763 22.8523C29.0705 22.2626 28.5859 21.7897 27.9962 21.7955C27.4035 21.8043 26.9306 22.2889 26.9393 22.8786Z'
        fill='#DE3700'
      />
    </svg>
  );
};

export const fireTagular = (type, webElement) => {
  tagular('beam', {
    '@type': type,
    webElement,
  });
};

export const fireEventTagular = (
  event,
  type,
  webElement,
  actionOutcome = ''
) => {
  tagular('beam', event, {
    '@type': type,
    webElement,
    actionOutcome,
  });
};

export const fireProductComparisonTagular = (message) => {
  fireTagular('redventures.usertracking.v3.ElementViewed', {
    elementType: 'PRODUCT CARD',
    location: 'section 2',
    position: 'HAS PRODUCT COMPARISION CARD',
    htmlId: '',
    text: message,
  });
};

export const fireOpenCardEventTagular = (text, action) => {
  fireEventTagular(
    'ElementClicked',
    'redventures.usertracking.v3.ElementClicked',
    {
      location: 'section 2',
      position: 'PRODUCT CARD COMPARISION',
      elementType: 'DROP DOWN CARROT',
      text,
      htmlId: '',
    },
    action
  );
};

export const fireElementViewedEvent = (
  elementType,
  location,
  position,
  message
) => {
  fireTagular('redventures.usertracking.v3.ElementViewed', {
    elementType: elementType,
    location: location,
    position: position,
    htmlId: '',
    text: message,
  });
};

import React from 'react';

export const ChevronDown = (props) => {
  return (
    <svg width='14px' viewBox='0 0 14 11' {...props}>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        transform='translate(-282.000000, -167.000000)'
      >
        <path
          d='M282.766667,172.283333 C282.655555,172.172222 282.6,172.03889 282.6,171.883333 L282.6,167.75 C282.6,167.505554 282.705555,167.338889 282.916667,167.25 C282.983334,167.216667 283.055555,167.2 283.133333,167.2 C283.266667,167.2 283.383333,167.25 283.483333,167.35 L289,172.566667 L294.5,167.35 C294.677779,167.194444 294.872221,167.161111 295.083333,167.25 C295.294445,167.35 295.4,167.516665 295.4,167.75 L295.4,171.883333 C295.4,172.050001 295.338889,172.183333 295.216667,172.283333 L289.35,177.733333 C289.116666,177.944446 288.877779,177.944446 288.633333,177.733333 L282.766667,172.283333 Z'
          fill='#154B8E'
        ></path>
      </g>
    </svg>
  );
};

import React from 'react';

export const SatelliteLogoBrand = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='71'
      height='46'
      viewBox='0 0 71 46'
      fill='none'
      {...props}
    >
      <path
        d='M37.4565 24.7064L28.2267 31.2659C28.0632 31.3821 27.874 31.477 27.6721 31.5492C27.4698 31.6214 27.2548 31.6709 27.0401 31.6959C26.8251 31.7209 26.6105 31.7217 26.4094 31.6963C26.2083 31.6709 26.0206 31.6202 25.8588 31.5416L17.8266 27.6499C17.8211 27.6472 17.8159 27.6448 17.8108 27.642C17.8052 27.6392 17.8001 27.6364 17.7949 27.6337C17.7897 27.6309 17.7846 27.6281 17.7794 27.6253C17.7743 27.6226 17.7695 27.6198 17.7644 27.617C17.637 27.5448 17.547 27.4563 17.493 27.3591C17.4395 27.2616 17.4224 27.1548 17.4406 27.0442C17.4589 26.9343 17.5125 26.8204 17.6009 26.7093C17.689 26.5987 17.8112 26.4904 17.9675 26.3904L27.1524 20.5068L37.4565 24.7064Z'
        fill='#0096FA'
      />
      <path
        d='M31.5864 14.4313L19.2973 22.056L12.5065 19.0649C12.4969 19.0605 12.4874 19.0565 12.4783 19.0522C12.4688 19.0478 12.4597 19.0434 12.4505 19.0391C12.4414 19.0347 12.4323 19.0303 12.4236 19.0256C12.4144 19.0212 12.4057 19.0165 12.397 19.0121C12.1693 18.8931 12.0126 18.7475 11.9249 18.5844C11.8376 18.4226 11.819 18.2441 11.8658 18.0592C11.9126 17.8751 12.0245 17.6851 12.199 17.4983C12.3732 17.3126 12.6096 17.1301 12.9063 16.9611L20.821 12.451C21.1015 12.2911 21.4201 12.1586 21.7557 12.0551C22.0909 11.9519 22.444 11.8777 22.7946 11.8353C23.1453 11.7924 23.4944 11.7813 23.8209 11.8043C24.1482 11.8269 24.4537 11.8841 24.7171 11.9777L31.5864 14.4313Z'
        fill='#0096FA'
      />
      <path
        d='M48.4912 15.5995C48.636 15.6543 48.7507 15.7225 48.8336 15.7995C48.9165 15.8764 48.9684 15.9621 48.9871 16.0514C49.0057 16.141 48.9911 16.2346 48.9419 16.3271C48.8927 16.4195 48.8086 16.5108 48.6888 16.596L40.8022 22.194L30.5264 18.2471L38.4403 13.1705C38.566 13.09 38.7144 13.0233 38.8754 12.9709C39.0365 12.919 39.2107 12.8817 39.3868 12.8607C39.5629 12.8392 39.7419 12.8341 39.9128 12.846C40.0838 12.8579 40.2473 12.8868 40.3925 12.9344L48.4218 15.5753C48.4277 15.5773 48.4337 15.5793 48.4396 15.5813C48.4456 15.5833 48.4511 15.5852 48.4571 15.5876C48.463 15.5896 48.4686 15.5916 48.4742 15.594C48.4801 15.5952 48.4857 15.5971 48.4912 15.5995Z'
        fill='#0096FA'
      />
      <path
        d='M58.0495 23.8847C58.4093 24.0311 58.7017 24.2112 58.9222 24.4131C59.144 24.6163 59.2931 24.8412 59.3649 25.0752C59.4367 25.3109 59.4304 25.5561 59.3403 25.7976C59.2495 26.0404 59.0746 26.2796 58.8099 26.5018L50.9492 33.0919C50.639 33.3517 50.2586 33.5644 49.8365 33.7258C49.4132 33.8881 48.9486 33.9984 48.4718 34.0535C47.9953 34.1086 47.5078 34.1086 47.0381 34.0499C46.5696 33.9916 46.1201 33.8754 45.7182 33.6981L36.2842 29.5414L48.5682 20.4949L57.8781 23.8193C57.8928 23.8248 57.9074 23.83 57.9221 23.8351C57.9368 23.8407 57.9511 23.8459 57.9654 23.8514C57.9796 23.857 57.9939 23.8625 58.0082 23.8681C58.0217 23.8732 58.0356 23.8792 58.0495 23.8847Z'
        fill='#0096FA'
      />
      <path
        d='M57.4772 29.983H57.1582V29.7993H58.0187V29.983H57.6997V30.9272H57.4772V29.983ZM58.7728 30.486L59.3238 29.7997H59.4139V30.9279H59.2076V30.2539L58.8081 30.7534H58.7403L58.3471 30.2571V30.9275H58.1377V29.7993H58.2313L58.7728 30.486Z'
        fill='#0096FA'
      />
    </svg>
  );
};

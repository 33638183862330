import { useState, useEffect } from 'react';
/**
 * Hook that returns if the current viewport matches mobile, tablet or desktop.
 * @returns {Object}  Object containing properties of 'device size: boolean'
 */
const useMedia = () => {
  const MOBILE_WIDTH = 768;
  const TABLET_WIDTH = 1025;
  const [width, setWidth] = useState(undefined);

  useEffect(() => {
    function handleResize() {
      // Set window width to state
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    mobile: width < MOBILE_WIDTH,
    tablet: width >= MOBILE_WIDTH && width < TABLET_WIDTH,
    desktop: width >= TABLET_WIDTH,
  };
};

export default useMedia;

import React from 'react';

export const Install = (props) => {
  return (
    <svg width='120px' viewBox='0 0 42 42' {...props}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g fill={props.fill || '#DDD'}>
          <path
            d='M21,42 C9.40202025,42 0,32.5979797 0,21 C0,9.40202025 9.40202025,0 21,0 C32.5979797,0 42,9.40202025 42,21 C42,32.5979797 32.5979797,42 21,42 Z M21,40.0615385 C31.527397,40.0615385 40.0615385,31.527397 40.0615385,21 C40.0615385,10.472603 31.527397,1.93846154 21,1.93846154 C10.472603,1.93846154 1.93846154,10.472603 1.93846154,21 C1.93846154,31.527397 10.472603,40.0615385 21,40.0615385 Z'
            fillRule='nonzero'
          ></path>
          <path d='M27,17.5048966 C26.4817584,17.5956928 26.008396,17.7502258 25.5833333,17.9701248 L25.5833333,15.5820313 C25.5833333,15.5429689 25.5636573,15.5039061 25.5243056,15.4648438 C25.4849539,15.4257814 25.4456017,15.40625 25.40625,15.40625 L11.59375,15.40625 C11.5543983,15.40625 11.5150461,15.4257814 11.4756944,15.4648438 C11.4363427,15.5039061 11.4166667,15.5429689 11.4166667,15.5820313 L11.4166667,23.6679688 C11.4166667,23.7070311 11.4363427,23.7460939 11.4756944,23.7851563 C11.5150461,23.8242186 11.5543983,23.84375 11.59375,23.84375 L23.5095,23.84375 C23.5216128,23.8843912 23.5341445,23.9250116 23.5470948,23.9656104 L22.4198636,25.25 L11.4166667,25.25 C11.0231483,25.25 10.6886573,25.1132811 10.4131944,24.8398438 C10.1377316,24.5664064 10,24.2343749 10,23.84375 L10,15.40625 C10,15.0156251 10.1377316,14.6835936 10.4131944,14.4101562 C10.6886573,14.1367189 11.0231483,14 11.4166667,14 L25.5833333,14 C25.9768517,14 26.3113427,14.1367189 26.5868056,14.4101562 C26.8622684,14.6835936 27,15.0156251 27,15.40625 L27,17.5048966 Z M19.5636244,29 L13.5416667,29 C13.3449073,29 13.1776622,28.9316408 13.0399306,28.7949219 C12.9021989,28.658203 12.8333333,28.4921876 12.8333333,28.296875 C12.8333333,28.1015624 12.9021989,27.935547 13.0399306,27.7988281 C13.1776622,27.6621092 13.3449073,27.59375 13.5416667,27.59375 L16.4635417,27.59375 L16.9947917,25.953125 C17.0538194,25.7968751 17.171875,25.71875 17.3489583,25.71875 L19.6510417,25.71875 C19.828125,25.71875 19.9461806,25.7968751 20.0052083,25.953125 L20.4896804,27.4492888 L20.1780159,27.8044055 C19.869967,28.1628512 19.664225,28.5648612 19.5636244,29 Z'></path>
          <path
            d='M33.5408455,21.5646655 C33.366567,21.3796136 33.1826092,21.3747439 32.9889664,21.5500562 L31.1445283,23.4784817 C30.9605677,23.6635336 30.7427229,23.775537 30.4909873,23.8144953 C30.2392517,23.8534536 30.0020429,23.8144959 29.7793537,23.697621 C29.4598432,23.5320483 29.1742244,23.3177809 28.9224888,23.0548125 C28.6610711,22.8015836 28.4480672,22.5142706 28.2834709,22.1928647 C28.1672852,21.9688545 28.1285573,21.7302386 28.1672858,21.4770097 C28.2060144,21.2237809 28.3173573,21.0046438 28.5013179,20.8195919 L30.3602791,18.9203849 C30.5151933,18.7255935 30.5103523,18.5454141 30.345756,18.3798414 C30.2392524,18.2824457 30.0214076,18.1947909 29.6922149,18.1168743 C29.3630222,18.0389577 28.9951065,18 28.5884567,18 C27.3588252,18 26.4148309,18.3262708 25.7564455,18.9788221 C25.1755172,19.5437172 24.839068,20.2644347 24.7470877,21.1409962 C24.6551074,22.0175577 24.7930758,22.894106 25.160997,23.7706674 L21.4285515,28.0804064 C21.1477695,28.4115518 21.0049601,28.7938243 21.000119,29.2272352 C20.995278,29.6606462 21.1380874,30.0477884 21.4285515,30.3886734 C21.7383799,30.7587771 22.1377621,30.9608702 22.6267101,30.9949587 C23.115658,31.0290472 23.5440862,30.8902604 23.9120075,30.5785941 L28.1963321,26.8532265 C28.9128103,27.1648928 29.6147548,27.3158539 30.3021866,27.3061143 C31.3672217,27.3061143 32.2482831,26.955495 32.945397,26.2542458 C33.3133182,25.8841421 33.584414,25.4409982 33.7586925,24.9248009 C33.932971,24.4086036 34.0128474,23.9240672 33.9983242,23.4711771 C33.983801,23.018287 33.9329706,22.6165357 33.8458313,22.2659111 C33.7586921,21.9152865 33.6570312,21.6815403 33.5408455,21.5646655 Z'
            fillRule='nonzero'
          ></path>
        </g>
      </g>
    </svg>
  );
};

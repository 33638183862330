import React from 'react';

export const FourFiveStars = (props) => {
  return (
    <svg
      width='114'
      height='17'
      viewBox='0 0 114 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.00001 1H10L11.4871 5.57681L16.2994 5.57681L16.9175 7.47892L13.0242 10.3075L14.5113 14.8843L12.8933 16.0599L9.00002 13.2313L5.10677 16.0599L3.48873 14.8843L4.97581 10.3076L1.08252 7.47893L1.70055 5.57682L6.51292 5.57681L8.00001 1Z'
        fill='#6DBD47'
        stroke='#111111'
        strokeWidth='0.5'
      />
      <path
        d='M32 1H34L35.4871 5.57681L40.2994 5.57681L40.9175 7.47892L37.0242 10.3075L38.5113 14.8843L36.8933 16.0599L33 13.2313L29.1068 16.0599L27.4887 14.8843L28.9758 10.3076L25.0825 7.47893L25.7006 5.57682L30.5129 5.57681L32 1Z'
        fill='#6DBD47'
        stroke='#111111'
        strokeWidth='0.5'
      />
      <path
        d='M56 1H58L59.4871 5.57681L64.2994 5.57681L64.9175 7.47892L61.0242 10.3075L62.5113 14.8843L60.8933 16.0599L57 13.2313L53.1068 16.0599L51.4887 14.8843L52.9758 10.3076L49.0825 7.47893L49.7006 5.57682L54.5129 5.57681L56 1Z'
        fill='#6DBD47'
        stroke='#111111'
        strokeWidth='0.5'
      />
      <path
        d='M80 1H82L83.4871 5.57681L88.2994 5.57681L88.9175 7.47892L85.0242 10.3075L86.5113 14.8843L84.8933 16.0599L81 13.2313L77.1068 16.0599L75.4887 14.8843L76.9758 10.3076L73.0825 7.47893L73.7006 5.57682L78.5129 5.57681L80 1Z'
        fill='#6DBD47'
        stroke='#111111'
        strokeWidth='0.5'
      />
      <path
        d='M105 1H104L102.513 5.57681L97.7006 5.57682L97.0825 7.47893L100.976 10.3076L99.4887 14.8843L101.107 16.0599L105 13.2313V1Z'
        fill='#6DBD47'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M103.818 0.75H106.182L107.669 5.32681L112.481 5.32681L113.211 7.57442L109.318 10.403L110.805 14.9798L108.893 16.3689L105 13.5403L101.107 16.3689L99.1948 14.9798L100.682 10.403L96.7886 7.57442L97.5189 5.32682L102.331 5.32681L103.818 0.75ZM104.182 1.25L102.695 5.82681L97.8822 5.82682L97.3764 7.38344L101.27 10.2121L99.7826 14.7889L101.107 15.7509L105 12.9223L108.893 15.7509L110.217 14.7888L108.73 10.2121L112.624 7.38343L112.118 5.82681L107.305 5.82681L105.818 1.25H104.182Z'
        fill='#111111'
      />
    </svg>
  );
};

import React from 'react';

export const CheckArrow = (props) => {
  return (
    <svg width='64' {...props}>
      <defs>
        <path d='M30 11h29v1H30z' />
        <mask x='0' y='0' width='29' height='1' fill='#fff'>
          <use xlinkHref='#abc' />
        </mask>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <path
          d='M23.643 2.259c.44.356.468 1.04.11 1.56 0 0-10.432 14.804-11.285 15.98-1.156 1.56-3.358 1.642-4.57 0C7.074 18.65.248 9.1.248 9.1c-.358-.52-.33-1.203.11-1.586.963-.821 2.009-1.533 3.138-2.162.495-.246 1.156-.055 1.513.465l5.175 7.224L19.02.562c.358-.52 1.019-.711 1.514-.438 1.101.602 2.147 1.287 3.11 2.135z'
          fill='#0096CB'
          fillRule='nonzero'
        />
        <path stroke='#0096CB' d='M64 11.5L59 14V9z' />
        <use
          stroke='#0096CB'
          mask='url(#bbc)'
          strokeWidth='4'
          strokeDasharray='3'
          xlinkHref='#abc'
        />
      </g>
    </svg>
  );
};

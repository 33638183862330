import React from 'react';

export const Computer = (props) => {
  const desc = props.desc ? props.desc : 'An icon of computer';
  return (
    <svg
      aria-labelledby='computerIconTitle computerIconDesc'
      {...props}
      role='img'
      viewBox='0 0 24 24'
    >
      <title id='computerIconTitle'>Computer Icon</title>
      <desc id='computerIconDesc'>{desc}</desc>
      <path
        fill={props.fill || 'currentColor'}
        d='M21.6 16.4V3.5C21.6 2.725 21.025 2.075 20.25 1.95C20.1 1.925 16.375 1.5 12 1.5C7.6 1.5 3.9 1.925 3.75 1.95C2.975 2.075 2.4 2.725 2.4 3.5V16.4C0.8 17 0 17.675 0 19.175C0 22.05 2.675 22.1 12 22.1C21.325 22.1 24 22.05 24 19.175C24 17.675 23.2 17 21.6 16.4ZM5.6 15.7V4.8C7.2 4.625 9.4 4.4 12 4.4C14.6 4.4 16.8 4.625 18.4 4.8V15.7H5.6ZM11.075 12.275L11.575 11.075C12.475 11.8 13.325 12.4 13.525 12.45C13.825 12.55 13.975 12.5 14.175 12.3C14.4 12.1 14.45 11.9 14.35 11.625C14.275 11.475 13.65 10.575 12.975 9.675L14.175 9.175C14.3 9.125 14.4 8.975 14.4 8.825C14.4 8.675 14.325 8.55 14.2 8.475C14.075 8.425 11.6 7.175 9.225 6.9C9.125 6.9 9 6.925 8.925 7C8.825 7.1 8.8 7.2 8.8 7.325C9.025 9.625 10.325 12.175 10.375 12.3C10.45 12.425 10.575 12.5 10.725 12.5C10.875 12.5 11.025 12.4 11.075 12.275Z'
      />
    </svg>
  );
};

import React from 'react';

export const DataTokenArrow = (props) => {
  return (
    <svg width='64' {...props}>
      <defs>
        <path d='M30 14h29v1H30z' />
        <mask x='0' y='0' width='29' height='1' fill='#fff'>
          <use xlinkHref='#aqw' />
        </mask>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <path
          d='M12 11.143c-6.635 0-12-2.496-12-5.572C0 2.496 5.365 0 12 0c6.606 0 12 2.496 12 5.571 0 3.076-5.394 5.572-12 5.572zm0 2.873c4.5 0 8.567-1.19 11.25-2.931.462.58.75 1.248.75 1.915 0 3.076-5.394 5.571-12 5.571-6.635 0-12-2.495-12-5.571 0-.667.288-1.335.75-1.915 2.683 1.74 6.721 2.93 11.25 2.93zM.75 18.513c2.683 1.741 6.721 2.931 11.25 2.931 4.5 0 8.567-1.19 11.25-2.93.462.58.75 1.247.75 1.915C24 23.504 18.606 26 12 26c-6.635 0-12-2.496-12-5.571 0-.668.288-1.335.75-1.916z'
          fill='#0096CB'
          fillRule='nonzero'
        />
        <path stroke='#0096CB' d='M64 14.5L59 17v-5z' />
        <use
          stroke='#0096CB'
          mask='url(#bqw)'
          strokeWidth='4'
          strokeDasharray='3'
          xlinkHref='#aqw'
        />
      </g>
    </svg>
  );
};

import { configureStore } from '@reduxjs/toolkit';

// Add slices
import counterReducer from '~/redux/counterSlice';

// Setup:
// https://redux-toolkit.js.org/tutorials/quick-start#create-a-redux-store
export const store = configureStore({
  devTools: process.env.ENVIRONMENT !== 'production',
  reducer: {
    counter: counterReducer,
  },
});

import React from 'react';

export const PageLeft = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <path
        d='M9.10828 1.06666L3.51453 7.80104L3.51453 8.20729L9.10828 14.9573L11.7333 13.4885L7.32703 8.01979L11.7333 2.58229L9.10828 1.06666Z'
        fill='black'
      />
    </svg>
  );
};

import React from 'react';

export const Rocket = (props) => {
  const desc = props.desc ? props.desc : 'An icon of rocket';
  return (
    <svg
      aria-labelledby='rocketIconTitle rocketIconDesc'
      role='img'
      viewBox='0 0 24 24'
      {...props}
    >
      <title id='rocketIconTitle'>Rocket Icon</title>
      <desc id='rocketIconDesc'>{desc}</desc>
      <path
        fill={props.fill || 'currentColor'}
        d='M3.4 19.8C3.4 19.8 3.5 18.3 4.875 15.875L2.375 13.375C1.325 15.5 0 18.6 0 20.75C0 21.525 0.175 22.175 0.625 22.6C1.05 23.025 1.7 23.2 2.475 23.2C4.6 23.2 7.7 21.875 9.825 20.825L7.325 18.325C4.9 19.7 3.4 19.8 3.4 19.8ZM13 21.175C13.575 21.75 14.375 21.25 14.375 20.625C14.425 18.825 14.9 15.725 15.925 14.675L18.5 11.95C23.325 7.125 23.2 1.05 23.2 0.774998C23.175 0.349999 22.85 0 22.4 0C22.25 0 15.875 0.0749989 11.25 4.7L8.525 7.275C7.35 8.45 3.875 8.775 2.575 8.825C1.975 8.825 1.4 9.575 2 10.2L13 21.175Z'
      />
    </svg>
  );
};

import React from 'react';

export const SatelliteInternetCircle = (props) => {
  return (
    <svg width='76px' viewBox='0 0 95 95' {...props}>
      <defs>
        <path d='M0 .553h25.702V26.21H0z' />
        <path d='M0 35.01h25.702V.564H0z' />
        <linearGradient x1='-9.479%' y1='-28.056%' x2='156.354%' y2='123.387%'>
          <stop stopColor='#174C8E' offset='0%' />
          <stop stopColor='#071220' offset='100%' />
        </linearGradient>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <circle fill='url(#a)' cx='47.5' cy='47.5' r='47.5' />
        <g transform='translate(35 30)'>
          <path
            d='M3.44 8.624a2.408 2.408 0 113.405-3.406A2.408 2.408 0 013.44 8.624'
            fill='#FFF'
          />
          <g transform='translate(0 .012)'>
            <mask fill='#fff'>
              <use xlinkHref='#b' />
            </mask>
            <path
              d='M13.648 21.505c.879 0 1.665.455 2.105 1.215l1.234 2.14a15.02 15.02 0 004.306-3.014c5.88-5.88 5.88-15.414 0-21.293L0 21.846a14.993 14.993 0 009.53 4.364l2.014-3.49a2.406 2.406 0 012.104-1.215'
              fill='#FFF'
              mask='url(#c)'
            />
          </g>
          <path
            fill='#FFF'
            d='M19.404 22.213L4.628 7.436l1.03-1.03 14.776 14.776zM13.648 25.971a1.057 1.057 0 110-2.114 1.057 1.057 0 010 2.114zm5.478 4.268h-.001l-4.094-7.09a1.597 1.597 0 00-2.766 0l-4.094 7.09-1.383 2.396h13.72l-1.382-2.396z'
          />
          <mask fill='#fff'>
            <use xlinkHref='#d' />
          </mask>
          <path
            fill='#FFF'
            mask='url(#e)'
            d='M5.319 35.01h16.66v-3.627H5.319z'
          />
        </g>
      </g>
    </svg>
  );
};

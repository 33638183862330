import React from 'react';

export const Wrench = (props) => {
  return (
    <svg width='37px' viewBox='0 0 43 43' {...props}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='HN---Homepage-2'
          transform='translate(-106.000000, -922.000000)'
          fill='#FFFFFF'
          fillRule='nonzero'
        >
          <path d='M115.921913,962.999989 L129.750087,951.046822 C132.000095,952.031201 134.296978,952.546828 136.546986,952.499953 C139.781372,952.499953 142.828258,951.374949 145.078266,949.124941 C150.140784,944.062423 148.406403,935.484268 147.000148,934.078013 C146.531396,933.562386 145.734518,933.562386 145.218891,934.031138 L139.265746,940.218659 C138.093866,941.390538 136.31261,941.671789 134.85948,940.921787 C133.828226,940.40616 132.890723,939.703033 132.093845,938.85928 C131.250092,938.062402 130.546965,937.124898 130.031338,936.093645 C129.281335,934.640515 129.562586,932.859258 130.734465,931.687379 C130.734465,931.687379 132.101206,930.299284 133.530071,928.848093 L133.938881,928.432894 C135.367746,926.981704 136.734487,925.593608 136.734487,925.593608 C137.156363,925.077981 137.156363,924.327978 136.687611,923.859227 C136.031359,923.24985 133.734476,922.640473 131.015716,922.640473 C127.921956,922.640473 124.312568,923.390475 121.875059,925.781109 C118.031296,929.531122 117.656295,935.437393 119.953178,941.156163 L107.90626,954.984336 C106.125004,957.093719 106.031254,960.234355 107.90626,962.390612 C109.921892,964.734371 113.53128,965.015622 115.921913,962.999989 Z'></path>
        </g>
      </g>
    </svg>
  );
};

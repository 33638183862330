import React from 'react';

export const Sync = (props) => {
  return (
    <svg width='24' viewBox='0 0 24 23' {...props}>
      <path
        d='M22.7027 1.225C22.6277 0.95 22.4277 0.699999 22.1277 0.624999C21.8527 0.524999 21.5527 0.624999 21.3277 0.825L19.2277 2.925C17.2527 1.125 14.6277 0 11.7277 0C7.20265 0 3.17765 2.825 1.57765 6.6C1.05265 7.8 1.17765 8.5 1.70265 8.675C2.32765 8.9 2.92765 8.4 4.07765 7.65C6.97765 5.725 9.42765 5.675 11.8027 6.175C12.7527 6.4 13.8277 6.825 14.6777 7.475L12.7027 9.375C12.5027 9.6 12.4277 9.9 12.5027 10.175C12.6027 10.45 12.8277 10.675 13.1027 10.725C14.6527 11.025 16.4527 11.2 18.4527 11.2C20.6027 11.2 22.2027 11 22.2527 11C22.6277 10.95 22.9027 10.675 22.9527 10.3C22.9777 10.1 23.5277 5.45 22.7027 1.225ZM12.1777 22.4C16.7027 22.4 20.7027 19.575 22.3277 15.8C22.8527 14.6 22.7027 13.9 22.1777 13.725C21.5777 13.525 20.9777 14 19.8277 14.75C16.9277 16.675 14.4527 16.725 12.1027 16.225C11.1277 16 10.0777 15.575 9.22765 14.925L11.2027 13.025C11.4027 12.8 11.4777 12.5 11.4027 12.225C11.3027 11.925 11.0777 11.725 10.7777 11.675C9.22765 11.375 7.42765 11.2 5.42765 11.2C3.27765 11.2 1.70265 11.4 1.62765 11.4C1.27765 11.45 0.977652 11.725 0.952652 12.1C0.927652 12.3 0.377652 16.975 1.20265 21.175C1.27765 21.45 1.47765 21.7 1.75265 21.775C1.82765 21.8 1.92765 21.825 2.00265 21.825C2.20265 21.825 2.40265 21.725 2.55265 21.575L4.65265 19.475C6.65265 21.3 9.27765 22.4 12.1777 22.4Z'
        fill={props.color || '#154B8E'}
      />
    </svg>
  );
};

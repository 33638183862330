import React from 'react';

export const Phone = (props) => {
  return (
    <svg width='20' viewBox='0 0 14 14' fill='#fff' {...props}>
      <path
        d='M8.02 9.315L9.407 8.06a.457.457 0 0 1 .38-.19l.247.088 3.238 1.634a.471.471 0 0 1 .262.437c-.068.875-.374 1.58-.918 2.115-.593.593-1.396.89-2.407.89-1.001 0-2.083-.283-3.244-.847-1.162-.563-2.254-1.356-3.274-2.377-.759-.768-1.4-1.59-1.925-2.464C1.24 6.47.868 5.63.649 4.823.43 4.016.369 3.258.467 2.548c.097-.71.37-1.288.816-1.736C1.76.337 2.49.054 3.471-.032c.185 0 .326.087.423.262l1.779 3.238a.598.598 0 0 1 .073.262.53.53 0 0 1-.175.38L4.185 5.48c-.165.185-.184.38-.058.583.418.69.904 1.308 1.458 1.853a10.006 10.006 0 0 0 1.853 1.472c.213.127.408.103.583-.072z'
        fillRule='evenodd'
      />
    </svg>
  );
};

import React from 'react';

export const Flash = (props) => {
  return (
    <svg width='24' viewBox='0 0 24 24' {...props}>
      <path
        d='M23.55 12.868L14.55 8.69298L20.65 1.29298C20.875 0.992976 20.875 0.567976 20.625 0.292976C20.4 -0.00702272 20 -0.0820244 19.675 0.0929758L0.425 9.69298C0.175 9.81798 0 10.118 0 10.418C0 10.718 0.175 10.993 0.45 11.118L9.45 15.293L3.35 22.693C3.1 22.993 3.125 23.418 3.35 23.693C3.5 23.893 3.725 23.993 3.975 23.993C4.1 23.993 4.2 23.968 4.325 23.918L23.575 14.293C23.825 14.168 24 13.893 24 13.568C24 13.268 23.825 12.993 23.55 12.868Z'
        fill={props.color || '#154B8E'}
      />
    </svg>
  );
};

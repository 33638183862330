import React from 'react';

export const Fast = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
    >
      <path
        d='M33.5396 10.7895C29.3716 6.9549 23.9115 5.07931 18.2431 5.57947C9.1985 6.37139 1.65442 13.4153 0.237305 22.4182C-0.346215 26.1694 0.153944 30.0456 1.65442 33.5467C1.82114 33.8802 2.07122 34.1719 2.44634 34.297C2.77978 34.422 3.19658 34.422 3.53002 34.2553C4.1969 33.9219 4.48866 33.1299 4.1969 32.4631C3.3633 30.5875 2.90482 28.5868 2.82146 26.4611C2.40466 17.75 8.53162 10.0392 16.9927 8.6221C22.0776 7.74682 27.2459 9.12226 31.1222 12.415C35.0401 15.7077 37.2491 20.5009 37.2491 25.5859C37.2491 28.0033 36.749 30.3374 35.7903 32.4631C35.4986 33.1716 35.7903 33.9635 36.4572 34.2553H36.4989C36.6656 34.3387 36.874 34.3803 37.0824 34.3803C37.2491 34.3803 37.4158 34.3386 37.5409 34.297C37.8743 34.1719 38.1661 33.9218 38.3328 33.5884C39.4582 31.0876 40 28.4201 40 25.5859C40 19.9591 37.6659 14.5823 33.5396 10.7895Z'
        fill='#0091C4'
      />
      <path
        d='M30.2468 32.1296H9.82364C9.11508 32.1296 8.57324 32.7548 8.57324 33.5051C8.57324 34.2553 9.11508 34.8805 9.82364 34.8805H30.2468C30.9554 34.8805 31.4972 34.2553 31.4972 33.5051C31.4972 32.7548 30.9554 32.1296 30.2468 32.1296Z'
        fill='#0091C4'
      />
      <path
        d='M29.8718 19.2088C29.3299 18.6669 28.4547 18.7086 27.9128 19.2921L19.8686 28.0866L19.8269 28.1283C19.3684 28.6701 19.4101 29.5037 19.9103 30.0039C20.2437 30.3373 20.7439 30.379 20.8689 30.379C21.244 30.379 21.6191 30.2123 21.9109 29.9205L29.9135 21.1677C30.4553 20.6676 30.4136 19.7923 29.8718 19.2088Z'
        fill='#0091C4'
      />
      <path
        d='M34.2064 29.6288C34.7588 29.6288 35.2067 29.181 35.2067 28.6285C35.2067 28.076 34.7588 27.6282 34.2064 27.6282C33.6539 27.6282 33.2061 28.076 33.2061 28.6285C33.2061 29.181 33.6539 29.6288 34.2064 29.6288Z'
        fill='#0091C4'
      />
      <path
        d='M34.2899 24.4606C34.8423 24.4606 35.2902 24.0127 35.2902 23.4603C35.2902 22.9078 34.8423 22.46 34.2899 22.46C33.7374 22.46 33.2896 22.9078 33.2896 23.4603C33.2896 24.0127 33.7374 24.4606 34.2899 24.4606Z'
        fill='#0091C4'
      />
      <path
        d='M32.4559 19.5421C33.0083 19.5421 33.4562 19.0943 33.4562 18.5418C33.4562 17.9894 33.0083 17.5415 32.4559 17.5415C31.9034 17.5415 31.4556 17.9894 31.4556 18.5418C31.4556 19.0943 31.9034 19.5421 32.4559 19.5421Z'
        fill='#0091C4'
      />
      <path
        d='M29.0799 15.6662C29.6324 15.6662 30.0802 15.2183 30.0802 14.6658C30.0802 14.1134 29.6324 13.6655 29.0799 13.6655C28.5274 13.6655 28.0796 14.1134 28.0796 14.6658C28.0796 15.2183 28.5274 15.6662 29.0799 15.6662Z'
        fill='#0091C4'
      />
      <path
        d='M24.4949 13.1237C25.0474 13.1237 25.4953 12.6758 25.4953 12.1234C25.4953 11.5709 25.0474 11.123 24.4949 11.123C23.9425 11.123 23.4946 11.5709 23.4946 12.1234C23.4946 12.6758 23.9425 13.1237 24.4949 13.1237Z'
        fill='#0091C4'
      />
      <path
        d='M14.2835 13.3319C14.836 13.3319 15.2838 12.8841 15.2838 12.3316C15.2838 11.7792 14.836 11.3313 14.2835 11.3313C13.7311 11.3313 13.2832 11.7792 13.2832 12.3316C13.2832 12.8841 13.7311 13.3319 14.2835 13.3319Z'
        fill='#0091C4'
      />
      <path
        d='M19.41 12.1649C19.9624 12.1649 20.4103 11.7171 20.4103 11.1646C20.4103 10.6122 19.9624 10.1643 19.41 10.1643C18.8575 10.1643 18.4097 10.6122 18.4097 11.1646C18.4097 11.7171 18.8575 12.1649 19.41 12.1649Z'
        fill='#0091C4'
      />
      <path
        d='M9.90706 16.2079C10.4595 16.2079 10.9074 15.7601 10.9074 15.2076C10.9074 14.6551 10.4595 14.2073 9.90706 14.2073C9.3546 14.2073 8.90674 14.6551 8.90674 15.2076C8.90674 15.7601 9.3546 16.2079 9.90706 16.2079Z'
        fill='#0091C4'
      />
    </svg>
  );
};

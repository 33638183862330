import { useEffect } from 'react';

/*
 * Props:
 * refs: An array of React elements via useRef hook
 * callback: any function that needs to run whenever the element is        intersecting. Optionally takes the intersection element's id as a parameter.
 * root (optional): The desired root element
 * options (optional): Intersection Observer options
 */
export default function useIntersectionObserver(refs, callback, root, options) {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (elements, ob) => {
        for (let el of elements) {
          if (el.isIntersecting) {
            // Call callback function when the element is intersecting
            callback(el.target);

            ob.unobserve(el.target);
          }
        }
      },
      {
        root,
        ...options,
      }
    );

    // Register an observer for each element
    refs.forEach((ref) => {
      if (!ref.current) return;

      observer.observe(ref.current);
    });

    // Destroy the observer when the parent component is destroyed
    return () => observer.disconnect();
  }, []);
}

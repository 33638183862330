import React from 'react';

export const Lock = (props) => {
  return (
    <svg width='18' viewBox='0 0 18 24' {...props}>
      <path
        d='M17.6 12.125C17.6 11.225 16.85 10.4 15.975 10.4H15.2V6.4C15.2 2.55 12.625 0 8.8 0C4.975 0 2.4 2.55 2.4 6.4V10.4H1.55C0.675 10.4 0 11.3 0 12.2V20.775C0 21.55 0.525 22.2 1.25 22.35C3.725 22.925 6.275 23.2 8.8 23.2C11.325 23.2 13.8 22.925 16.275 22.35C17 22.2 17.6 21.55 17.6 20.775V12.125ZM6.4 10.4V6.4C6.4 5.375 6.65 4 8.8 4C10.95 4 11.2 5.375 11.2 6.4V10.4H6.4Z'
        fill={props.color || '#154B8E'}
      />
    </svg>
  );
};

import React from 'react';

export const CheckMarkBlue = (props) => {
  return (
    <svg width='16px' viewBox='0 0 16 12' {...props}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          transform='translate(-350.000000, -388.000000)'
          fill='#0091C4'
          fillRule='nonzero'
        >
          <path d='M355.406237,399.500001 C355.510403,399.500001 355.593737,399.468751 355.656237,399.406251 L355.656237,399.406251 L365.031214,390.031274 C365.114547,389.94794 365.156214,389.854191 365.156214,389.750024 C365.156214,389.645858 365.114547,389.562525 365.031214,389.500027 L365.031214,389.500027 L364.156216,388.593777 C364.072883,388.531277 363.979133,388.500027 363.874967,388.500027 C363.770801,388.500027 363.687467,388.531277 363.624968,388.593777 L363.624968,388.593777 L355.406237,396.843758 L352.374994,393.812515 C352.312494,393.729181 352.229161,393.687515 352.124995,393.687515 C352.020829,393.687515 351.927079,393.729181 351.843748,393.812515 L351.843748,393.812515 L350.968748,394.687513 C350.885414,394.770846 350.843748,394.864596 350.843748,394.968762 C350.843748,395.072928 350.885414,395.156262 350.968748,395.218761 L350.968748,395.218761 L355.124988,399.406251 C355.208321,399.468751 355.302071,399.500001 355.406237,399.500001 Z M355.406237,399.500001 C355.510403,399.500001 355.593737,399.468751 355.656237,399.406251 L355.656237,399.406251 L365.031214,390.031274 C365.114547,389.94794 365.156214,389.854191 365.156214,389.750024 C365.156214,389.645858 365.114547,389.562525 365.031214,389.500027 L365.031214,389.500027 L364.156216,388.593777 C364.072883,388.531277 363.979133,388.500027 363.874967,388.500027 C363.770801,388.500027 363.687467,388.531277 363.624968,388.593777 L363.624968,388.593777 L355.406237,396.843758 L352.374994,393.812515 C352.312494,393.729181 352.229161,393.687515 352.124995,393.687515 C352.020829,393.687515 351.927079,393.729181 351.843748,393.812515 L351.843748,393.812515 L350.968748,394.687513 C350.885414,394.770846 350.843748,394.864596 350.843748,394.968762 C350.843748,395.072928 350.885414,395.156262 350.968748,395.218761 L350.968748,395.218761 L355.124988,399.406251 C355.208321,399.468751 355.302071,399.500001 355.406237,399.500001 Z'></path>
        </g>
      </g>
    </svg>
  );
};

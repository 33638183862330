import React from 'react';

export const WifiSignal = (props) => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40 20C40 31.0492 31.016 40.0427 19.9786 39.9998C8.94118 39.9998 0 31.0063 0 20C0 8.95083 8.98396 -0.0426739 19.9786 0.000152319C31.0588 0.0429785 40 8.99366 40 20ZM20.0214 2.5269C10.3957 2.5269 2.56684 10.3641 2.56684 20C2.52406 29.6359 10.3957 37.4731 20.0214 37.4731C29.6471 37.4731 37.4759 29.6359 37.4759 20C37.4759 10.4069 29.6471 2.5269 20.0214 2.5269ZM8.47058 17.8159C7.91445 17.2163 7.4011 16.6168 6.88775 16.0172L6.88769 16.0172C14.1176 9.50758 25.6684 9.29344 33.1551 16.0172L33.1537 16.0188C32.6408 16.6178 32.1278 17.2169 31.5722 17.8587C28.2353 14.9893 24.385 13.4904 20.0214 13.4904C15.6577 13.4904 11.8503 14.9893 8.47058 17.8159ZM12.1497 21.3276C16.5561 17.3876 23.5294 17.4732 27.8502 21.3276C28.3636 20.728 28.877 20.1285 29.4331 19.5289C23.8289 14.5182 15.4438 15.0321 10.5668 19.5289C10.8235 19.8287 11.0909 20.1285 11.3583 20.4282C11.6256 20.728 11.893 21.0278 12.1497 21.3276ZM26.0107 23.1263C25.4973 23.7259 24.984 24.3255 24.4278 24.9679C23.1444 23.8972 21.6898 23.3405 20.0642 23.3405C18.3957 23.3405 16.9412 23.8972 15.7005 24.9679C15.1444 24.3683 14.631 23.7687 14.1177 23.1692C17.2834 20.1285 22.631 20.0428 26.0107 23.1263ZM21.5187 27.4946C21.5187 28.3224 20.8483 28.9935 20.0214 28.9935C19.1944 28.9935 18.5241 28.3224 18.5241 27.4946C18.5241 26.6668 19.1944 25.9957 20.0214 25.9957C20.8483 25.9957 21.5187 26.6668 21.5187 27.4946Z'
        fill={props.color || '#0091c4'}
      />
    </svg>
  );
};

import React from 'react';

export const PlayArrow = (props) => {
  return (
    <svg width='64' {...props}>
      <defs>
        <path d='M30 12h29v1H30z' />
        <mask x='0' y='0' width='29' height='1' fill='#fff'>
          <use xlinkHref='#ab' />
        </mask>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <path
          d='M25.39 4.307a42.9 42.9 0 0 1 .61 7.207c0 2.412-.203 4.796-.61 7.179a4.626 4.626 0 0 1-4.033 3.819A73.748 73.748 0 0 1 13 23c-2.786 0-5.6-.172-8.357-.488a4.626 4.626 0 0 1-4.034-3.82A42.561 42.561 0 0 1 0 11.515a42.9 42.9 0 0 1 .61-7.207C.957 2.297 2.611.717 4.642.488A73.748 73.748 0 0 1 13 0c2.786 0 5.571.172 8.357.488 2.031.23 3.685 1.81 4.034 3.82zm-6.964 7.896a.907.907 0 0 0 .349-.717.868.868 0 0 0-.349-.718c-.145-.144-3.859-3.302-8.734-5.284-.261-.114-.58-.086-.87.058a.963.963 0 0 0-.436.746c-.029.087-.232 2.585-.232 5.198 0 2.641.203 5.082.232 5.197.03.287.174.574.435.718a.902.902 0 0 0 .523.143c.116 0 .232-.028.348-.057 4.875-1.981 8.59-5.14 8.734-5.284z'
          fill='#0096CB'
          fillRule='nonzero'
        />
        <path stroke='#0096CB' d='M64 12.5L59 15v-5z' />
        <use
          stroke='#0096CB'
          mask='url(#bb)'
          strokeWidth='4'
          strokeDasharray='3'
          xlinkHref='#ab'
        />
      </g>
    </svg>
  );
};

import React from 'react';

export const HughesnetLogo = (props) => {
  return (
    <svg
      id={'HughesnetIcon' + props.id}
      aria-labelledby={
        'HughesnetIconTitle' + props.id + ' HughesnetIconDesc' + props.id
      }
      fill={props.fill || '#0091c4'}
      viewBox='0 0 373.2 60.7'
      width='209'
      {...props}
    >
      <title id={'HughesnetIconTitle' + props.id}>Hughesnet Icon</title>
      <desc id={'HughesnetIconDesc' + props.id}>An icon of Hughesnet</desc>
      <g>
        <g>
          <path
            className='st0'
            d='M31,29H14.2v17.9H0V0h14.2v16.6H31V0h14.2v46.9H31V29z'
          />
          <path
            className='st0'
            d='M75.5,43.1c-2,2.2-5.7,4.6-11.7,4.6c-7.9,0-11.3-4.7-11.3-10.6V12.9h12.7v18.9c0,3.4,1.8,4.8,4.9,4.8    c2.8,0,4.3-1.5,5.3-2.7v-21h12.7v34H75.5V43.1z'
          />
          <path
            className='st0'
            d='M99.3,46.6c2.6,2.6,6.5,3.7,10.1,3.7c3,0,7.9-1.1,7.9-7.1v-1.8c-2.9,3.3-6,4.6-9.6,4.6    c-8.2,0-14.9-5.3-14.9-17c0-11.3,6.5-17,14.9-17c3.5,0,6.8,1.2,9.6,4.4v-3.6H130v30.3c0,14.7-12.1,17.4-20.5,17.4    c-5.8,0-11-1.5-15.5-5.1L99.3,46.6z M117.3,25.5c-1.2-1.4-3.6-2.2-5.2-2.2c-3.4,0-6.3,1.6-6.3,5.8c0,4.1,2.9,5.8,6.3,5.8    c1.6,0,4-0.8,5.2-2.2V25.5z'
          />
          <path
            className='st0'
            d='M160.1,28c0-3.5-1.9-4.7-4.9-4.7c-2.9,0-4.4,1.5-5.4,2.7v21h-12.7V0h12.7v16.8c2-2.3,5.7-4.7,11.7-4.7    c7.9,0,11.3,4.6,11.3,10.6v24.3h-12.7V28z'
          />
          <path
            className='st0'
            d='M195.9,12.1c10,0,17.7,7.2,17.7,19.1v2.6h-23.1c0.7,2.2,3.2,4.3,7.5,4.3c2.9,0,6-1.1,7.7-2.4l5.2,7.8    c-3.4,2.9-9.4,4.2-14.5,4.2c-10.6,0-18.9-6.7-18.9-17.9C177.4,20,185,12.1,195.9,12.1z M190.4,25.8h11.2c-0.4-1.5-1.5-4.1-5.6-4.1    C192.1,21.7,190.8,24.3,190.4,25.8z'
          />
          <path
            className='st0'
            d='M220.1,34c2.7,2.1,8.4,4.3,12.2,4.3c2.6,0,3.4-0.7,3.4-1.6c0-1.1-1.5-1.5-4.8-2c-5.7-0.8-14.5-1.8-14.5-11    c0-6.1,5.3-11.6,15.2-11.6c5.7,0,10.8,1.8,14.7,4.4l-4.6,8.2c-2.1-1.8-6-3.5-10-3.5c-1.8,0-3.2,0.5-3.2,1.5c0,1,1,1.4,4.4,1.9    c5.7,0.8,14.9,2,14.9,11.5c0,6.8-6,11.7-16.1,11.7c-6.3,0-13-2.2-16.7-5.2L220.1,34z'
          />
          <path
            className='st0'
            d='M262.1,12.7v34.2h-8.2V0h8.4l24,33.3V0h8.2v46.9h-7.9L262.1,12.7z'
          />
          <path
            className='st0'
            d='M319.4,12.1c10.2,0,16.7,7.8,16.7,18.5v1.8h-26.2c0.6,5.1,4.4,9.4,10.8,9.4c3.3,0,7.2-1.3,9.6-3.7l3.4,4.9    c-3.4,3.2-8.4,4.9-13.8,4.9c-10.2,0-17.8-7.1-17.8-17.9C302.2,20,309.4,12.1,319.4,12.1z M309.9,27.1H329c-0.1-4-2.9-9-9.6-9    C313.1,18.1,310.2,23,309.9,27.1z'
          />
          <path
            className='st0'
            d='M345,39V19.4h-5.6v-6.5h5.6V3.7h7.4v9.3h6.9v6.5h-6.9v17.8c0,2.3,1.1,4,3.2,4c1.4,0,2.7-0.6,3.3-1.2l1.8,5.6    c-1.3,1.3-3.5,2.2-7,2.2C348,47.8,345,44.7,345,39z'
          />
        </g>
        <g>
          <g>
            <path
              className='st0'
              d='M366.7,45.5v-4.6h2c0.5,0,0.9,0,1.1,0.1c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.5,0.2,0.7     c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.2-0.5,0.4-0.9,0.4c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.1,0.3,0.4,0.5,0.8l0.6,0.9h-1.1l-0.7-1     c-0.2-0.4-0.4-0.6-0.5-0.7c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.5-0.1h-0.2v1.9H366.7z M367.7,42.8h0.7     c0.4,0,0.7,0,0.8-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2     c-0.1,0-0.3,0-0.7,0h-0.7V42.8z'
            />
          </g>
          <g>
            <path
              className='st0'
              d='M368.7,47.8c-2.4,0-4.4-2-4.4-4.4c0-2.4,2-4.4,4.4-4.4c2.4,0,4.4,2,4.4,4.4     C373.2,45.8,371.2,47.8,368.7,47.8z M368.7,39.7c-2,0-3.7,1.6-3.7,3.7c0,2,1.6,3.7,3.7,3.7s3.7-1.6,3.7-3.7     C372.4,41.3,370.8,39.7,368.7,39.7z'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

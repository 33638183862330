import React from 'react';

export const MoneySign = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      {...props}
    >
      <path
        d='M27.0288 43.4001V40.2791C23.4272 39.967 20.786 38.5799 18.8994 36.6726L21.5749 32.8234C22.8441 34.1759 24.7306 35.3896 27.0288 35.8057V30.4307C23.4958 29.5638 19.654 28.2113 19.654 23.4952C19.654 19.9928 22.3981 17.0105 27.0288 16.525V13.3H30.2531V16.5944C33.0315 16.9065 35.3983 17.9468 37.2506 19.6807L34.5408 23.3565C33.3059 22.2121 31.7967 21.4839 30.2531 21.1025V25.888C33.8205 26.7896 37.7994 28.1767 37.7994 32.9275C37.7994 36.7767 35.2954 39.7589 30.2531 40.2791V43.4001H27.0288ZM32.8257 33.413C32.8257 32.2686 31.7624 31.7138 30.2531 31.2283V35.8751C32.0025 35.5283 32.8257 34.5226 32.8257 33.413ZM24.662 23.1138C24.662 24.1194 25.6225 24.6396 27.0288 25.0904V20.8944C25.5196 21.1025 24.662 21.9347 24.662 23.1138Z'
        fill={props.color || '#0091c4'}
      />
      <rect
        x='1.75'
        y='1.75'
        width='52.5'
        height='52.5'
        rx='26.25'
        stroke='#FF5820'
        strokeWidth='3.5'
      />
    </svg>
  );
};

import React from 'react';

export const WifiArrow = (props) => {
  return (
    <svg width='68' {...props}>
      <defs>
        <path d='M34 14h29v1H34z' />
        <mask x='0' y='0' width='29' height='1' fill='#fff'>
          <use xlinkHref='#afd' />
        </mask>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <g fill='#0096CB'>
          <path
            d='M16 25c-2.21 0-4-1.567-4-3.5s1.79-3.5 4-3.5 4 1.567 4 3.5-1.79 3.5-4 3.5zM24.194 15.61c-.884.617-2.154.49-2.864-.296-1.494-1.653-3.67-2.59-5.95-2.564-2.071.024-4.188.961-5.768 2.563-.745.756-2.02.837-2.874.186-.892-.679-.991-1.895-.212-2.685 2.342-2.374 5.546-3.776 8.803-3.813 3.543-.04 6.913 1.404 9.202 3.936.742.82.584 2.03-.337 2.674z'
            fillRule='nonzero'
          />
          <path d='M30.137 9.571c.986-.701 1.154-2.012.363-2.904C26.694 2.379 21.096-.066 15.213 0 9.812.064 4.473 2.448.562 6.488c-.832.86-.727 2.177.227 2.916.924.717 2.306.628 3.11-.203 3.09-3.192 7.253-5.065 11.37-5.113 4.515-.052 8.82 1.835 11.77 5.158.766.863 2.142 1.004 3.098.325z' />
        </g>
        <path stroke='#0096CB' d='M68 14.5L63 17v-5z' />
        <use
          stroke='#0096CB'
          mask='url(#bfd)'
          strokeWidth='4'
          strokeDasharray='3'
          xlinkHref='#afd'
        />
      </g>
    </svg>
  );
};

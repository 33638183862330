import React from 'react';

export const ChevronDownV2 = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='11'
      viewBox='0 0 16 11'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.4142 3.41412L8.00003 10.8283L0.585815 3.41412L3.41424 0.585694L8.00003 5.17148L12.5858 0.585693L15.4142 3.41412Z'
      />
    </svg>
  );
};

import React from 'react';

export const CheckMark = (props) => {
  return (
    <svg width='18px' viewBox='0 0 18 18' {...props}>
      <path
        d='M17.733 1.721c.33.271.35.792.082 1.188 0 0-7.824 11.28-8.464 12.176-.867 1.188-2.519 1.251-3.427 0-.62-.876-5.74-8.152-5.74-8.152-.267-.396-.247-.917.083-1.209a14.466 14.466 0 0 1 2.354-1.647c.372-.188.867-.042 1.135.354l3.881 5.504L14.264.428c.269-.396.764-.542 1.136-.333.826.458 1.61.98 2.333 1.626z'
        fillRule='nonzero'
      />
    </svg>
  );
};

import React from 'react';
import Script from 'next/script';

function NewRelic() {
  return (
    <Script
      id='newRelic'
      strategy='beforeInteractive'
      src={
        process.env.ENVIRONMENT === 'production'
          ? '/js/newrelic.js'
          : '/js/newrelic-dev.js'
      }
    />
  );
}

export default NewRelic;

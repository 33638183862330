import React from 'react';

export const HughesnetLogoBrand = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='160'
      height='46'
      viewBox='0 0 160 46'
      fill='none'
      {...props}
    >
      <g clipPath='url(#clip0_3322_881)'>
        <path
          d='M1.30814 18.5554L4.3428 16.6167C4.65511 16.4164 5.06758 16.6403 5.06758 17.0174V24.5835C5.80415 23.2282 7.6603 21.9318 10.1646 21.9318C13.3761 21.9318 16.4402 24.0826 16.4402 28.6493V38.0479C16.4402 38.372 16.175 38.6372 15.8509 38.6372H11.8145C11.4904 38.6372 11.2253 38.372 11.2253 38.0479V30.0341C11.2253 27.8833 10.1352 26.6753 8.3674 26.6753C6.51125 26.6753 5.18543 28.0601 5.18543 30.1519V38.0479C5.18543 38.372 4.92027 38.6372 4.59618 38.6372H0.589254C0.265164 38.6372 0 38.372 0 38.0479V20.9419C0 19.9755 0.494973 19.0798 1.30814 18.5554Z'
          fill='#005DAC'
        />
        <path
          d='M128.905 28.9027H136.506C136.094 27.076 134.739 25.8975 132.794 25.8975C130.849 25.8975 129.435 27.0171 128.905 28.9027ZM140.289 32.2025H128.934C129.435 33.9703 130.82 35.2372 133.324 35.2372C134.851 35.2372 136.583 34.7128 137.862 33.988C138.144 33.8289 138.504 33.9173 138.669 34.2001L139.918 36.3627C140.071 36.6337 139.995 36.9755 139.735 37.1464C138.162 38.1894 135.846 39.0673 133.118 39.0673C127.078 39.0673 124.279 35.0015 124.279 30.6116C124.279 25.8091 127.668 22.1558 132.794 22.1558C137.42 22.1558 140.955 25.1315 140.955 30.258C140.955 30.7294 140.926 31.2067 140.873 31.6781C140.843 31.9727 140.584 32.2025 140.289 32.2025Z'
          fill='#0096FA'
        />
        <path
          d='M154.466 37.3408C154.561 37.6178 154.449 37.9183 154.189 38.0538C152.923 38.7256 151.231 39.1263 149.682 39.1263C145.91 39.1263 143.907 36.946 143.907 32.615V26.3984H141.933C141.609 26.3984 141.344 26.1332 141.344 25.8091V23.1575C141.344 22.8334 141.609 22.5682 141.933 22.5682H143.936V21.0067C143.936 19.9637 144.508 19.0091 145.427 18.5142L147.837 17.2237C148.15 17.0528 148.533 17.2826 148.533 17.6362V22.5623H153.129C153.453 22.5623 153.718 22.8275 153.718 23.1516V25.8032C153.718 26.1273 153.453 26.3925 153.129 26.3925H148.533V32.1672C148.533 34.0233 149.24 34.9072 150.683 34.9072C151.467 34.9072 152.133 34.6833 152.758 34.4005C153.07 34.2531 153.441 34.4122 153.559 34.7422L154.472 37.3349L154.466 37.3408Z'
          fill='#0096FA'
        />
        <path
          d='M48.319 30.3286C48.319 28.2073 46.7575 26.6458 44.6362 26.6458C42.5149 26.6458 40.9533 28.1779 40.9533 30.3286C40.9533 32.4794 42.5149 34.0999 44.6362 34.0999C46.7575 34.0999 48.319 32.4794 48.319 30.3286ZM52.5145 21.1127C52.8268 20.9124 53.2393 21.1363 53.2393 21.5075V36.9872C53.2393 42.261 49.2029 45.443 43.988 45.443C41.2715 45.443 38.6376 44.5768 36.7166 43.2922C36.4691 43.1272 36.3925 42.809 36.5222 42.5439L37.8951 39.7744C38.0483 39.468 38.4254 39.356 38.7201 39.5269C40.0989 40.3401 41.8608 41.0472 43.7818 41.0472C46.8459 41.0472 48.2896 39.3383 48.2896 37.3054V36.9518C47.2584 37.9241 45.7852 38.5723 43.7523 38.5723C38.8615 38.5723 35.709 35.0957 35.709 30.2638C35.709 25.4319 39.3329 21.8669 43.988 21.8669C45.962 21.8669 47.4646 22.6035 48.5547 23.6347L50.1045 22.6448L52.5145 21.1068V21.1127Z'
          fill='#005DAC'
        />
        <path
          d='M157.914 36.9873H158.179C158.35 36.9873 158.456 36.9814 158.497 36.9637C158.539 36.9519 158.574 36.9283 158.598 36.893C158.621 36.8576 158.633 36.8164 158.633 36.7633C158.633 36.7044 158.615 36.6573 158.586 36.6219C158.556 36.5866 158.509 36.563 158.45 36.5571C158.421 36.5571 158.332 36.5512 158.191 36.5512H157.914V36.9873ZM157.561 37.9831V36.2566H158.309C158.497 36.2566 158.633 36.2743 158.721 36.3037C158.81 36.3332 158.875 36.3921 158.928 36.4687C158.981 36.5453 159.004 36.6396 159.004 36.7398C159.004 36.8694 158.963 36.9755 158.886 37.058C158.81 37.1405 158.692 37.1935 158.539 37.2171C158.615 37.2583 158.68 37.3114 158.727 37.3644C158.774 37.4174 158.845 37.5117 158.928 37.6413L159.14 37.9772H158.715L158.462 37.6001C158.368 37.4646 158.309 37.3821 158.274 37.3467C158.238 37.3114 158.203 37.2878 158.167 37.276C158.132 37.2642 158.073 37.2583 157.991 37.2583H157.92V37.9772H157.566L157.561 37.9831Z'
          fill='#005DAC'
        />
        <path
          d='M158.32 35.8088C157.554 35.8088 156.929 36.4216 156.929 37.1699C156.929 37.9183 157.554 38.5311 158.32 38.5311C159.086 38.5311 159.711 37.9183 159.711 37.1699C159.711 36.4216 159.086 35.8088 158.32 35.8088ZM158.32 38.8198C157.395 38.8198 156.641 38.0774 156.641 37.1699C156.641 36.2625 157.395 35.52 158.32 35.52C159.245 35.52 159.999 36.2625 159.999 37.1699C159.999 38.0774 159.245 38.8198 158.32 38.8198Z'
          fill='#005DAC'
        />
        <path
          d='M78.4296 28.5314H85.3238C84.8819 26.9993 83.6739 26.027 81.9356 26.027C80.1973 26.027 78.9599 26.9109 78.4296 28.5314ZM89.5841 32.0964H78.4885C79.0188 33.6284 80.3152 34.6596 82.466 34.6596C83.9627 34.6596 85.642 34.1646 86.8854 33.4634C87.1682 33.3043 87.5277 33.3986 87.6867 33.6814L89.1422 36.1858C89.3013 36.4627 89.2129 36.8104 88.9478 36.9813C87.3155 38.0243 85.0174 38.9022 82.2303 38.9022C76.1609 38.9022 73.2441 34.8659 73.2441 30.417C73.2441 25.5556 76.7207 21.8728 81.9356 21.8728C86.6202 21.8728 90.2736 24.8485 90.2736 30.0045C90.2736 30.4995 90.2382 31.0298 90.1675 31.5837C90.1322 31.8783 89.8788 32.0964 89.5841 32.0964Z'
          fill='#005DAC'
        />
        <path
          d='M91.2041 35.7674L92.8952 33.2218C93.0838 32.939 93.4727 32.8742 93.7379 33.0804C94.9694 34.0114 96.5074 34.7185 98.1101 34.7185C99.0529 34.7185 99.8484 34.3945 99.8484 33.6874C99.8484 33.3633 99.6716 33.0686 98.9645 32.774C98.1691 32.4794 95.9004 31.8017 94.9282 31.3598C93.0131 30.4759 91.8935 29.0912 91.8935 26.9404C91.8935 23.8763 94.5157 21.7844 98.611 21.7844C100.597 21.7844 102.453 22.2617 104.232 23.5581C104.486 23.7466 104.557 24.0943 104.38 24.3595L102.759 26.8343C102.588 27.0995 102.235 27.1761 101.964 27.017C100.656 26.2392 99.3888 25.9328 98.5226 25.9328C97.6564 25.9328 97.0495 26.3158 97.0495 26.8461C97.0495 27.347 97.3736 27.73 98.5226 28.0246C99.4949 28.2898 101.056 28.7612 102.353 29.4388C104.327 30.4406 105.122 31.7075 105.122 33.5636C105.122 36.6867 102.5 38.9847 98.1396 38.9847C95.4997 38.9847 93.1191 38.0891 91.3042 36.5334C91.0803 36.339 91.0391 36.009 91.2041 35.7615'
          fill='#005DAC'
        />
        <path
          d='M18.3379 31.4659V22.8922C18.3379 22.5681 18.6031 22.303 18.9271 22.303H22.9635C23.2876 22.303 23.5528 22.5681 23.5528 22.8922V31.5248C23.5528 32.3321 23.7885 32.9861 24.2599 33.4811C24.7313 33.982 25.3618 34.2354 26.1455 34.2354C26.9292 34.2354 27.5892 33.9879 28.0606 33.4988C28.532 33.0097 28.7677 32.3498 28.7677 31.5248V22.8922C28.7677 22.5681 29.0328 22.303 29.3569 22.303H33.3933C33.7174 22.303 33.9826 22.5681 33.9826 22.8922V31.4659C33.9826 33.8406 33.2932 35.6908 31.9084 37.0049C30.5237 38.3189 28.6027 38.9789 26.1514 38.9789C23.7001 38.9789 21.7791 38.3189 20.4062 37.0049C19.0332 35.6908 18.3438 33.8406 18.3438 31.4659'
          fill='#005DAC'
        />
        <path
          d='M118.582 38.6313C118.258 38.6313 117.993 38.3661 117.993 38.042V29.71C117.993 27.9127 116.532 26.4573 114.734 26.4573C114.11 26.4573 113.491 26.6282 112.896 26.9699C112.118 27.4178 111.482 28.5256 111.482 29.4448V38.042C111.482 38.3661 111.216 38.6313 110.892 38.6313H107.357C107.033 38.6313 106.768 38.3661 106.768 38.042V28.8497H106.779V28.7141C106.95 25.0431 110.45 22.1616 114.758 22.1616C119.065 22.1616 122.448 24.9606 122.725 28.5315V28.6493L122.742 28.6552V38.042C122.742 38.3661 122.477 38.6313 122.153 38.6313H118.588H118.582Z'
          fill='#0096FA'
        />
        <path
          d='M120.955 9.78143L114.374 14.4601C114.256 14.5426 114.12 14.6133 113.979 14.6605C113.837 14.7135 113.684 14.7488 113.531 14.7665C113.378 14.7842 113.224 14.7842 113.083 14.7665C112.942 14.7488 112.806 14.7135 112.688 14.6546L106.961 11.8792C106.961 11.8792 106.955 11.8792 106.949 11.8792C106.949 11.8792 106.943 11.8792 106.937 11.8733C106.931 11.8674 106.931 11.8733 106.925 11.8674C106.925 11.8674 106.919 11.8674 106.914 11.8674C106.825 11.8144 106.76 11.7554 106.719 11.6847C106.678 11.614 106.666 11.5374 106.684 11.4608C106.696 11.3842 106.737 11.3017 106.796 11.2251C106.861 11.1485 106.943 11.0719 107.055 10.9953L113.602 6.7998L120.95 9.79321L120.955 9.78143Z'
          fill='#0096FA'
        />
        <path
          d='M116.773 2.45105L108.01 7.88986L103.167 5.75676C103.167 5.75676 103.155 5.75087 103.149 5.75087C103.143 5.75087 103.137 5.74498 103.131 5.73908C103.125 5.73908 103.119 5.73319 103.114 5.7273C103.108 5.7273 103.102 5.72141 103.096 5.71551C102.931 5.63302 102.819 5.52695 102.76 5.4091C102.695 5.29125 102.683 5.16751 102.719 5.03198C102.754 4.90234 102.831 4.76681 102.955 4.63129C103.078 4.49576 103.249 4.36612 103.461 4.24827L109.106 1.03095C109.307 0.918987 109.531 0.824706 109.772 0.748103C110.014 0.677393 110.261 0.62436 110.515 0.589005C110.762 0.559542 111.015 0.547757 111.245 0.565435C111.481 0.583112 111.699 0.62436 111.882 0.689178L116.778 2.43926L116.773 2.45105Z'
          fill='#0096FA'
        />
        <path
          d='M128.828 3.28779C128.928 3.32904 129.011 3.37618 129.07 3.42921C129.128 3.48225 129.164 3.54707 129.181 3.60599C129.193 3.67081 129.181 3.73563 129.152 3.80045C129.117 3.86526 129.058 3.93008 128.969 3.9949L123.348 7.98415L116.023 5.16751L121.668 1.5495C121.757 1.49057 121.863 1.44343 121.981 1.40807C122.099 1.37272 122.222 1.34326 122.346 1.33147C122.47 1.31379 122.6 1.31379 122.723 1.31969C122.847 1.33147 122.959 1.34915 123.065 1.3845L128.793 3.27012H128.804C128.804 3.27012 128.81 3.27012 128.816 3.27012C128.816 3.27012 128.822 3.27012 128.828 3.27012C128.828 3.27012 128.834 3.27012 128.84 3.27012'
          fill='#0096FA'
        />
        <path
          d='M135.64 9.19206C135.893 9.29813 136.106 9.42187 136.265 9.56919C136.424 9.71061 136.53 9.8756 136.583 10.0406C136.636 10.2115 136.63 10.3824 136.565 10.5532C136.5 10.7241 136.377 10.895 136.188 11.0541L130.584 15.7564C130.36 15.939 130.089 16.0922 129.789 16.2101C129.488 16.3279 129.158 16.4045 128.816 16.4458C128.475 16.487 128.127 16.487 127.797 16.4458C127.461 16.4045 127.143 16.322 126.854 16.1924L120.125 13.2285L128.881 6.77612L135.522 9.14492C135.522 9.14492 135.546 9.15082 135.552 9.15671C135.563 9.15671 135.575 9.1626 135.581 9.16849C135.593 9.16849 135.605 9.17439 135.611 9.18028C135.622 9.18028 135.628 9.18617 135.64 9.19206Z'
          fill='#0096FA'
        />
        <path
          d='M56.6382 18.5554L59.6729 16.6167C59.9852 16.4164 60.3977 16.6403 60.3977 17.0174V24.5835C61.1342 23.2282 62.9904 21.9318 65.4947 21.9318C68.7061 21.9318 71.7703 24.0826 71.7703 28.6493V38.0479C71.7703 38.372 71.5051 38.6372 71.181 38.6372H67.1446C66.8205 38.6372 66.5554 38.372 66.5554 38.0479V30.0341C66.5554 27.8833 65.4652 26.6753 63.6975 26.6753C61.8413 26.6753 60.5155 28.0601 60.5155 30.1519V38.0479C60.5155 38.372 60.2503 38.6372 59.9263 38.6372H55.9193C55.5952 38.6372 55.3301 38.372 55.3301 38.0479V20.9419C55.3301 19.9755 55.8251 19.0798 56.6382 18.5554Z'
          fill='#005DAC'
        />
      </g>
      <defs>
        <clipPath id='clip0_3322_881'>
          <rect
            width='160'
            height='44.8859'
            fill='white'
            transform='translate(0 0.557129)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

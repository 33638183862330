import React from 'react';

export const Account = (props) => {
  return (
    <svg width='17' viewBox='0 0 17 18' {...props}>
      <path
        d='M15.9509 16.8136C16.2343 16.725 16.4114 16.4417 16.3405 16.1406C15.9864 14.4761 15.0655 13.5906 13.8082 12.7938C13.4541 12.5813 13.0291 12.4042 12.5686 12.2094C11.7009 11.8729 10.727 11.4833 10.5145 10.8281C10.3905 10.4031 10.5676 9.81877 11.0457 9.07502C12.5155 6.7021 12.7811 4.64793 11.8249 3.00106C11.0811 1.72606 9.82386 0.9646 8.4249 0.9646C7.04365 0.9646 5.76865 1.72606 5.0249 3.00106C4.06865 4.64793 4.33427 6.7021 5.82177 9.07502C6.2999 9.81877 6.47698 10.4031 6.33531 10.8281C6.12281 11.4833 5.14886 11.8729 4.29886 12.2094C3.82073 12.4042 3.37802 12.5813 3.04156 12.7938C1.78427 13.5906 0.863438 14.4761 0.509271 16.1406C0.438438 16.4417 0.615522 16.725 0.898855 16.8136C0.969688 16.8313 2.9176 17.3979 8.4249 17.3979C11.1697 17.3979 13.0291 17.2563 14.2155 17.1146C15.402 16.9729 15.9155 16.8136 15.9509 16.8136Z'
        fill='#676767'
      />
    </svg>
  );
};

import React from 'react';

export const Wifi = (props) => {
  return (
    <svg width='24' viewBox='0 0 24 20' {...props}>
      <path
        d='M12 0.25C7.2 0.25 2.925 2.35 0 5.675L2.75 8.25C4.975 5.65 8.3 4 12 4C15.7 4 19.025 5.65 21.25 8.25L24 5.675C21.075 2.35 16.775 0.25 12 0.25ZM12 6.65C9.1 6.65 6.525 8.075 4.8 10.3L7.5 13.05C8.525 11.6 10.175 10.65 12 10.65C13.825 10.65 15.475 11.6 16.5 13.05L19.2 10.3C17.475 8.075 14.9 6.65 12 6.65ZM12 13.05C10.225 13.05 8.8 14.475 8.8 16.25C8.8 18.025 10.225 19.45 12 19.45C13.775 19.45 15.2 18.025 15.2 16.25C15.2 14.475 13.775 13.05 12 13.05Z'
        fill={props.color || '#154B8E'}
      />
    </svg>
  );
};

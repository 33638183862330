import React from 'react';

export const Card100 = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill={props.color || '#0091c4'}
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M52 12.5H4C3.72386 12.5 3.5 12.7239 3.5 13V42.01C3.5 42.2861 3.72386 42.51 4 42.51H52C52.2761 42.51 52.5 42.2861 52.5 42.01V13C52.5 12.7239 52.2761 12.5 52 12.5ZM4 9C1.79086 9 0 10.7909 0 13V42.01C0 44.2191 1.79086 46.01 4 46.01H52C54.2091 46.01 56 44.2191 56 42.01V13C56 10.7909 54.2091 9 52 9H4Z'
        fill='#FF5820'
      />
      <path
        d='M14.6928 34.5257V33.1233C13.0567 32.983 11.8568 32.3597 10.9998 31.5027L12.2152 29.773C12.7918 30.3807 13.6488 30.9261 14.6928 31.1131V28.6978C13.0878 28.3082 11.3426 27.7005 11.3426 25.5813C11.3426 24.0074 12.5892 22.6673 14.6928 22.4492V21H16.1576V22.4803C17.4198 22.6206 18.495 23.0881 19.3365 23.8672L18.1054 25.519C17.5445 25.0047 16.8588 24.6775 16.1576 24.5061V26.6565C17.7782 27.0616 19.5858 27.6849 19.5858 29.8198C19.5858 31.5494 18.4482 32.8895 16.1576 33.1233V34.5257H14.6928ZM17.3263 30.0379C17.3263 29.5237 16.8432 29.2744 16.1576 29.0562V31.1443C16.9523 30.9885 17.3263 30.5366 17.3263 30.0379ZM13.6176 25.4099C13.6176 25.8618 14.0539 26.0955 14.6928 26.2981V24.4126C14.0072 24.5061 13.6176 24.8801 13.6176 25.4099Z'
        fill='#FF5820'
      />
      <path
        d='M25.48 32.9674H23.2673V25.4255L21.5376 27.1863L20.2754 25.8618L23.5478 22.5738H25.48V32.9674Z'
        fill='#FF5820'
      />
      <path
        d='M31.3411 33.1544C28.3649 33.1544 27.0403 30.4586 27.0403 27.7784C27.0403 25.0982 28.3649 22.418 31.3411 22.418C34.3174 22.418 35.6419 25.0982 35.6419 27.7784C35.6419 30.4586 34.3174 33.1544 31.3411 33.1544ZM31.3411 31.191C32.8215 31.191 33.3825 29.6951 33.3825 27.7784C33.3825 25.8618 32.8371 24.3814 31.3411 24.3814C29.8608 24.3814 29.2998 25.8618 29.2998 27.7784C29.2998 29.6951 29.8608 31.191 31.3411 31.191Z'
        fill='#FF5820'
      />
      <path
        d='M41.0042 33.1544C38.0279 33.1544 36.7034 30.4586 36.7034 27.7784C36.7034 25.0982 38.0279 22.418 41.0042 22.418C43.9805 22.418 45.305 25.0982 45.305 27.7784C45.305 30.4586 43.9805 33.1544 41.0042 33.1544ZM41.0042 31.191C42.4845 31.191 43.0455 29.6951 43.0455 27.7784C43.0455 25.8618 42.5001 24.3814 41.0042 24.3814C39.5238 24.3814 38.9629 25.8618 38.9629 27.7784C38.9629 29.6951 39.5238 31.191 41.0042 31.191Z'
        fill='#FF5820'
      />
    </svg>
  );
};

import React from 'react';

export const HomeBolt = (props) => {
  return (
    <svg width='99px' viewBox='0 0 99 94' {...props}>
      <defs>
        <rect x='0' y='0' width='99' height='94'></rect>
      </defs>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-93.000000, -2686.000000)'>
          <g transform='translate(93.000000, 2686.000000)'>
            <mask fill='white'>
              <use xlinkHref='#path-1'></use>
            </mask>
            <g></g>
            <g mask='url(#mask-2)' fill='#0096CB' fillRule='nonzero'>
              <g transform='translate(4.000000, -24.000000)'>
                <path
                  d='M56,112 C25.072054,112 0,86.927946 0,56 C0,25.072054 25.072054,0 56,0 C86.927946,0 112,25.072054 112,56 C112,86.927946 86.927946,112 56,112 Z M56,107 C84.1665222,107 107,84.1665222 107,56 C107,27.8334778 84.1665222,5 56,5 C27.8334778,5 5,27.8334778 5,56 C5,84.1665222 27.8334778,107 56,107 Z'
                  stroke='none'
                ></path>
                <path
                  d='M48.0000844,75.3999944 C52.6001006,75.3999944 57.2001168,74.8999926 61.7501328,73.9999894 C62.7001361,73.7499886 63.5001389,72.9499858 63.6001393,71.9499822 L65.6501465,57.7999325 L69.6001604,57.7999325 C70.9001649,57.7999325 72.0001688,56.7499288 72.0001688,55.399924 C72.0001688,54.7499218 71.7501679,54.1999198 71.3501665,53.7499182 C69.6001604,51.2499094 67.6501535,48.8999012 65.6001463,46.7498936 L65.6001463,33.7998481 C65.6001463,32.8998449 64.9001438,32.1998425 64.0001407,32.1998425 L59.2001238,32.1998425 C58.3001206,32.1998425 57.6001182,32.8998449 57.6001182,33.7998481 L57.6001182,39.0498665 C53.1501025,35.2498532 49.6500902,32.7998446 49.3500891,32.5998439 C48.5500863,32.0498419 47.4500825,32.0498419 46.6500797,32.5998439 C46.0500775,32.9998453 32.6000302,42.299878 24.4500016,54.0499193 C23.8999996,54.7499218 23.8499995,55.6999251 24.2500009,56.4999279 C24.7000025,57.2999307 25.5000053,57.7999325 26.4000084,57.7999325 L30.3500223,57.7999325 L32.3500294,71.9499822 C32.5000299,72.9499858 33.2500325,73.7499886 34.250036,73.9999894 C38.7500519,74.8999926 43.4000682,75.3999944 48.0000844,75.3999944 Z M48.0000844,69.2499727 C46.4000788,69.2499727 44.8000731,69.1499724 43.2000675,68.9999719 C42.4500649,68.8999715 41.8500628,68.2999694 41.8000626,67.4999666 L41.4000612,61.0999441 C41.4000612,60.6499425 41.5500617,60.2499411 41.8500628,59.8999381 C42.1500638,59.5999388 42.5500652,59.3999381 43.0000668,59.3999381 L53.000102,59.3999381 C53.4501036,59.3999381 53.850105,59.5999388 54.150106,59.8999381 C54.4501071,60.2499411 54.6001076,60.6499425 54.6001076,61.0999441 L54.2001062,67.4999666 C54.150106,68.2999694 53.5501039,68.8999715 52.8001013,68.9999719 C51.2000957,69.1499724 49.60009,69.2499727 48.0000844,69.2499727 Z'
                  stroke='none'
                ></path>
                <path
                  d='M50.7968954,97.3898741 C50.9791877,97.3898741 51.1250215,97.3534157 51.3073138,97.2804988 L79.3803292,83.2439911 C79.7449139,83.0616988 80.0001231,82.6606557 80.0001231,82.1866957 C80.0001231,81.7491941 79.7449139,81.3481511 79.3438708,81.1658588 L66.2188246,75.0772957 L75.1146892,64.2855911 C75.4428154,63.8480895 75.4428154,63.2282957 75.0782308,62.8272526 C74.7501046,62.3897511 74.1667692,62.2803757 73.6928092,62.5355849 L45.6197938,76.5356341 C45.2552092,76.7179264 45,77.155428 45,77.5929295 C45,78.0304311 45.2552092,78.4314741 45.6562523,78.6137664 L58.7812985,84.7023295 L49.8854338,95.4940341 C49.5208492,95.9315357 49.5573077,96.5513295 49.8854338,96.9523726 C50.1041846,97.2440403 50.4323108,97.3898741 50.7968954,97.3898741 Z'
                  stroke='#FFFFFF'
                  strokeWidth='3'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

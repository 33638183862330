import React from 'react';

export const ComputerWrench = (props) => {
  return (
    <svg width='130' viewBox='0 0 130 130' {...props}>
      <path
        d='M65 130C100.899 130 130 100.899 130 65C130 29.1015 100.899 0 65 0C29.1015 0 0 29.1015 0 65C0 100.899 29.1015 130 65 130ZM65 124C32.4152 124 6 97.5848 6 65C6 32.4152 32.4152 6 65 6C97.5848 6 124 32.4152 124 65C124 97.5848 97.5848 124 65 124Z'
        fill='#DDDDDD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46.4286 54.1819C48.0326 54.4629 49.4978 54.9412 50.8135 55.6219V48.2301C50.8135 48.1092 50.8744 47.9883 50.9962 47.8674C51.118 47.7465 51.2398 47.6861 51.3616 47.6861H94.1146C94.2364 47.6861 94.3582 47.7465 94.48 47.8674C94.6018 47.9883 94.6627 48.1092 94.6627 48.2301V73.258C94.6627 73.3789 94.6018 73.4999 94.48 73.6208C94.3582 73.7417 94.2364 73.8021 94.1146 73.8021H57.2325C57.195 73.9279 57.1562 74.0536 57.1161 74.1793L60.6052 78.1548H94.6627C95.8807 78.1548 96.916 77.7316 97.7687 76.8853C98.6213 76.0389 99.0476 75.0112 99.0476 73.8021V47.6861C99.0476 46.477 98.6213 45.4493 97.7687 44.6029C96.916 43.7566 95.8807 43.3334 94.6627 43.3334H50.8135C49.5954 43.3334 48.5601 43.7566 47.7075 44.6029C46.8549 45.4493 46.4286 46.477 46.4286 47.6861V54.1819ZM69.4459 89.7619H88.0853C88.6943 89.7619 89.212 89.5504 89.6383 89.1272C90.0646 88.704 90.2778 88.1901 90.2778 87.5856C90.2778 86.9811 90.0646 86.4672 89.6383 86.044C89.212 85.6209 88.6943 85.4093 88.0853 85.4093H79.0414L77.3971 80.3311C77.2144 79.8475 76.8489 79.6057 76.3008 79.6057H69.1753C68.6272 79.6057 68.2618 79.8475 68.0791 80.3311L66.5795 84.9621L67.5442 86.0613C68.4977 87.1708 69.1345 88.4151 69.4459 89.7619Z'
        fill='#DDDDDD'
      />
      <path
        d='M26.1831 66.7477C26.7225 66.1749 27.2919 66.1599 27.8913 66.7025L33.6003 72.6714C34.1697 73.2442 34.8439 73.5909 35.6231 73.7115C36.4023 73.8321 37.1365 73.7115 37.8258 73.3497C38.8148 72.8372 39.6988 72.174 40.478 71.3601C41.2872 70.5763 41.9465 69.687 42.4559 68.6921C42.8155 67.9988 42.9354 67.2602 42.8155 66.4764C42.6957 65.6926 42.351 65.0143 41.7816 64.4415L36.0277 58.563C35.5482 57.9601 35.5632 57.4024 36.0727 56.8899C36.4023 56.5885 37.0766 56.3172 38.0955 56.076C39.1145 55.8348 40.2532 55.7142 41.5119 55.7142C45.3179 55.7142 48.2398 56.7241 50.2777 58.7439C52.0758 60.4924 53.1172 62.7232 53.4019 65.4364C53.6866 68.1495 53.2595 70.8627 52.1207 73.5758L63.6735 86.9155C64.5426 87.9405 64.9846 89.1237 64.9996 90.4652C65.0146 91.8067 64.5726 93.005 63.6735 94.0601C62.7145 95.2057 61.4784 95.8312 59.9649 95.9367C58.4515 96.0422 57.1254 95.6127 55.9866 94.648L42.7256 83.1171C40.508 84.0818 38.3353 84.549 36.2075 84.5189C32.911 84.5189 30.1839 83.4336 28.0261 81.2631C26.8873 80.1175 26.0482 78.7459 25.5088 77.1481C24.9694 75.5504 24.7221 74.0506 24.7671 72.6488C24.812 71.247 24.9694 70.0035 25.2391 68.9182C25.5088 67.833 25.8235 67.1095 26.1831 66.7477Z'
        fill='#DDDDDD'
      />
    </svg>
  );
};

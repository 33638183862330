import React from 'react';

export const Home = (props) => {
  const desc = props.desc ? props.desc : 'An icon of house';
  return (
    <svg
      aria-labelledby='houseIconTitle houseIconDesc'
      role='img'
      viewBox='0 0 25 23'
      {...props}
    >
      <title id='houseIconTitle'>House Icon</title>
      <desc id='houseIconDesc'>{desc}</desc>
      <path
        fill={props.fill || 'currentColor'}
        d='M12.0124 22.0125C14.3124 22.0125 16.6124 21.7625 18.8874 21.3125C19.3624 21.1875 19.7624 20.7875 19.8124 20.2875L20.8374 13.2125H22.8124C23.4624 13.2125 24.0124 12.6875 24.0124 12.0125C24.0124 11.6875 23.8874 11.4125 23.6874 11.1875C22.8124 9.9375 21.8374 8.7625 20.8124 7.6875V1.2125C20.8124 0.762501 20.4624 0.4125 20.0124 0.4125H17.6124C17.1624 0.4125 16.8124 0.762501 16.8124 1.2125V3.8375C14.5874 1.9375 12.8374 0.7125 12.6874 0.6125C12.2874 0.3375 11.7374 0.3375 11.3374 0.6125C11.0374 0.812501 4.31244 5.4625 0.237442 11.3375C-0.0375582 11.6875 -0.0625585 12.1625 0.137442 12.5625C0.362442 12.9625 0.762442 13.2125 1.21244 13.2125H3.18744L4.18744 20.2875C4.26244 20.7875 4.63744 21.1875 5.13744 21.3125C7.38744 21.7625 9.71244 22.0125 12.0124 22.0125ZM15.1124 18.0625C15.0874 18.4625 14.7874 18.7625 14.4124 18.8125C13.6124 18.8875 12.8124 18.9375 12.0124 18.9375C11.2124 18.9375 10.4124 18.8875 9.61244 18.8125C9.23744 18.7625 8.93744 18.4625 8.91244 18.0625L8.71244 14.8625C8.71244 14.6375 8.78744 14.4375 8.93744 14.2625C9.08744 14.1125 9.28744 14.0125 9.51244 14.0125H14.5124C14.7374 14.0125 14.9374 14.1125 15.0874 14.2625C15.2374 14.4375 15.3124 14.6375 15.3124 14.8625L15.1124 18.0625Z'
      />
    </svg>
  );
};

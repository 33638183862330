import React from 'react';

export const FastSpeeds = (props) => {
  return (
    <svg viewBox='0 0 44 43' width='120px' {...props}>
      <g fillRule='evenodd' fill='none' strokeWidth='1' stroke='none'>
        <g fill='#DDD'>
          <path d='M28,19.5206836 C27.8299408,19.430017 27.6540082,19.3378714 27.4721843,19.2442293 C27.0455909,19.0245273 26.6215358,18.8187613 26.2,18.6269542 L26.2,15.75 L11.8,15.75 L11.8,23.625 L22.1872018,23.625 C22.1861534,23.748708 22.1856291,23.8715014 22.1856291,23.9933806 C22.1856291,24.4406492 22.1926904,24.9011788 22.2068007,25.375 L20.040625,25.375 L20.040625,26.25 L22.2399892,26.25 C22.266908,26.8533977 22.2939921,27.3203509 22.3216329,27.6543438 C22.3328921,27.7903924 22.3425688,27.9049543 22.3508745,28 L13.740625,28 C13.4968749,28 13.2859374,27.9134113 13.1078125,27.7402344 C12.9296876,27.5670574 12.840625,27.3619793 12.840625,27.125 C12.840625,26.8880207 12.9296876,26.6829426 13.1078125,26.5097656 C13.2859374,26.3365887 13.4968749,26.25 13.740625,26.25 L18.240625,26.25 L18.240625,25.375 L11.35,25.375 C10.9750001,25.375 10.6562499,25.2473957 10.39375,24.9921875 C10.1312501,24.7369793 10,24.4270832 10,24.0625 L10,15.3125 C10,14.9479168 10.1312501,14.6380207 10.39375,14.3828125 C10.6562499,14.1276043 10.9750001,14 11.35,14 L26.65,14 C27.0249999,14 27.3437501,14.1276043 27.60625,14.3828125 C27.8687499,14.6380207 28,14.9479168 28,15.3125 L28,19.5206836 Z'></path>
          <path
            fillRule='nonzero'
            d='M32.71875,23.4125 C32.6505678,23.345833 32.5099442,23.2312508 32.296875,23.06875 C32.0838058,22.9062492 31.7301161,22.6520851 31.2357955,22.30625 C30.7414748,21.9604149 30.2173323,21.6145851 29.6633523,21.26875 C29.1093722,20.9229149 28.4382142,20.5458354 27.649858,20.1375 C26.8615017,19.7291646 26.0838107,19.3708349 25.3167614,19.0625 C25.2059654,19.0208331 25.0994323,19 24.9971591,19 C24.8437492,19 24.7031256,19.0374996 24.5752841,19.1125 C24.3366465,19.2625007 24.2088069,19.4666654 24.1917614,19.725 C24.1832386,19.7750002 24.1640626,19.9854148 24.134233,20.35625 C24.1044033,20.7270852 24.074574,21.2645798 24.0447443,21.96875 C24.0149146,22.6729202 24,23.3499967 24,24 C24,24.6500033 24.0149146,25.3291631 24.0447443,26.0375 C24.074574,26.7458369 24.1044033,27.2812482 24.134233,27.64375 C24.1640626,28.0062518 24.1832386,28.220833 24.1917614,28.2875 C24.2088069,28.5458346 24.3366465,28.7458326 24.5752841,28.8875 C24.7031256,28.9625004 24.8437492,29 24.9971591,29 C25.0994323,29 25.2059654,28.9791669 25.3167614,28.9375 C27.8395012,27.937495 30.3068061,26.4916761 32.71875,24.6 C32.9062509,24.4416659 33,24.2416679 33,24 C33,23.7499988 32.9062509,23.5541674 32.71875,23.4125 Z'
          ></path>
          <path
            fillRule='nonzero'
            d='M21,42 C9.40202025,42 0,32.5979797 0,21 C0,9.40202025 9.40202025,0 21,0 C32.5979797,0 42,9.40202025 42,21 C42,32.5979797 32.5979797,42 21,42 Z M21,40.0615385 C31.527397,40.0615385 40.0615385,31.527397 40.0615385,21 C40.0615385,10.472603 31.527397,1.93846154 21,1.93846154 C10.472603,1.93846154 1.93846154,10.472603 1.93846154,21 C1.93846154,31.527397 10.472603,40.0615385 21,40.0615385 Z'
          ></path>
        </g>
      </g>
    </svg>
  );
};

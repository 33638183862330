import React from 'react';

export const GamingRebrand = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='31'
      height='22'
      viewBox='0 0 31 22'
      fill='none'
      {...props}
    >
      <g clipPath='url(#clip0_4945_2044)'>
        <path
          d='M6.06957 20.1194C5.86061 20.1194 5.68151 20.2985 5.68151 20.5075V20.9403C5.68151 21.1493 5.86061 21.3284 6.06957 21.3284H16.9949C17.2039 21.3284 17.383 21.1493 17.383 20.9403V20.5374C17.383 20.3284 17.2039 20.1493 16.9949 20.1493H12.1293V18.3284H20.577C21.5024 18.3284 22.1293 17.6269 22.2487 16.9702L22.2636 16.9105V6.11944L22.2487 6.02989C22.2338 6.00004 22.2337 5.98511 22.2337 5.95526C22.2337 5.94034 22.2337 5.92541 22.2188 5.92541L22.2039 5.89556C22.0397 5.26869 21.4278 4.64183 20.592 4.67168H18.1591C18.0546 4.67168 17.9502 4.71646 17.8606 4.79108C17.786 4.86571 17.7412 4.97019 17.7412 5.07467V5.46272C17.7412 5.68661 17.9203 5.86571 18.1442 5.86571H20.7561C20.9203 5.89556 21.0099 5.95526 21.0397 6.23884V6.34332V16.6568C21.0397 16.7165 21.0397 16.7762 21.0248 16.8657C20.9949 17.015 20.8457 17.1344 20.7114 17.1344H2.23375C2.06957 17.1045 1.98002 17.0448 1.95017 16.7762V16.6717V6.3284C1.95017 6.2687 1.95017 6.20899 1.96509 6.13437C1.99494 5.95526 2.09942 5.86571 2.27852 5.86571H4.78599C5.00987 5.86571 5.18897 5.68661 5.18897 5.46272V5.07467C5.18897 4.95526 5.1442 4.86571 5.05464 4.77616C4.98002 4.70153 4.87554 4.67168 4.75614 4.67168H2.41285C1.53226 4.65675 0.920315 5.3284 0.785987 5.97019C0.785987 6.00004 0.785987 6.02989 0.756136 6.05974L0.741211 6.08959V16.8657L0.756136 16.9702C0.771062 17 0.771062 17.015 0.771062 17.0448C0.771062 17.0597 0.785987 17.0747 0.785987 17.1045C0.994942 17.8359 1.63673 18.3284 2.41285 18.3284H10.8755V20.1344L6.06957 20.1194Z'
          fill='#0096FA'
          stroke='#0096FA'
          strokeMiterlimit='10'
        />
        <path
          d='M29.4726 21.3283H21.1741C20.5621 21.3283 20.0845 20.8358 20.0845 20.2239V19.9254C20.0845 19.6418 20.3084 19.4179 20.592 19.4179C20.8756 19.4179 21.0994 19.6418 21.0994 19.9254V20.2239C21.0994 20.2686 21.1442 20.3134 21.189 20.3134H29.4726C29.5472 20.3134 29.592 20.2836 29.592 20.2239V1.76118C29.592 1.70148 29.5472 1.67163 29.4726 1.67163H21.1741C21.1293 1.67163 21.0845 1.71641 21.0845 1.76118V3.20894C21.0845 3.49253 20.8606 3.71641 20.577 3.71641C20.2935 3.71641 20.0696 3.49253 20.0696 3.20894V1.76118C20.0696 1.14924 20.5621 0.671631 21.1591 0.671631H29.4726C30.0994 0.671631 30.592 1.14924 30.592 1.76118V20.2239C30.592 20.8507 30.0994 21.3283 29.4726 21.3283Z'
          fill='#0096FA'
          stroke='#0096FA'
          strokeMiterlimit='10'
        />
        <path
          d='M26.801 4.67166H23.8756C23.592 4.67166 23.3682 4.44778 23.3682 4.1642C23.3682 3.88062 23.592 3.65674 23.8756 3.65674H26.801C27.0846 3.65674 27.3085 3.88062 27.3085 4.1642C27.3085 4.44778 27.0697 4.67166 26.801 4.67166Z'
          fill='#0096FA'
        />
        <path
          d='M11.5025 14.0149C11.5174 14.0149 11.5472 14.0149 11.5622 14.0299L14.1293 14.9254C14.2935 14.9851 14.4577 14.9702 14.6219 14.8955C14.786 14.8209 14.8905 14.7015 14.9502 14.5373C14.9801 14.4627 14.995 14.3731 14.9801 14.2985L14.8607 11.7612C14.8607 11.7164 14.8756 11.6717 14.9055 11.6418L16.592 9.65673C16.8159 9.38807 16.786 8.98509 16.5174 8.76121C16.4428 8.70151 16.3532 8.65673 16.2637 8.62688L13.6219 7.94031C13.5771 7.92539 13.5472 7.91046 13.5174 7.86569L12.0249 5.73136C11.9204 5.59703 11.7711 5.49255 11.6069 5.4627C11.4428 5.43285 11.2637 5.47763 11.1293 5.56718C11.0696 5.61195 11.0099 5.65673 10.9801 5.71643L9.47262 7.85076C9.44277 7.88061 9.41291 7.91046 9.36814 7.92539L6.72635 8.61196C6.38306 8.70151 6.17411 9.04479 6.26366 9.38807C6.29351 9.47763 6.33829 9.56718 6.39799 9.64181L8.09948 11.6418C8.12933 11.6717 8.14426 11.7164 8.14426 11.7612L8.02485 14.2985C8.00993 14.5075 8.11441 14.7164 8.27859 14.8508C8.38306 14.9254 8.51739 14.9702 8.66665 14.9702C8.74127 14.9702 8.8159 14.9552 8.8756 14.9403L11.4577 14.0448C11.4726 14.0149 11.4875 14.0149 11.5025 14.0149ZM9.53232 13.3284C9.50247 13.3284 9.45769 13.3134 9.42784 13.2985C9.38306 13.2687 9.35321 13.209 9.35321 13.1493L9.42784 11.5075C9.42784 11.3433 9.38306 11.194 9.27859 11.0597L8.23381 9.82091C8.18903 9.77613 8.18903 9.71643 8.20396 9.65673C8.21888 9.59703 8.26366 9.55225 8.32336 9.53733L9.995 9.10449C10.1443 9.05972 10.2637 8.97016 10.3532 8.85076L11.3681 7.41793C11.4278 7.32837 11.592 7.32837 11.6517 7.41793L12.6666 8.85076C12.7562 8.97016 12.8756 9.05972 13.0249 9.10449L14.6965 9.53733C14.7562 9.55225 14.801 9.59703 14.8159 9.65673C14.8308 9.71643 14.8159 9.77613 14.786 9.82091L13.7413 11.0597C13.6368 11.1791 13.5771 11.3433 13.592 11.5075L13.6666 13.1493C13.6666 13.209 13.6368 13.2687 13.592 13.2985C13.5472 13.3284 13.4875 13.3433 13.4278 13.3284L11.7263 12.7314C11.592 12.6866 11.4428 12.6866 11.3084 12.7314L9.59202 13.3284C9.56217 13.3284 9.54724 13.3284 9.53232 13.3284Z'
          fill='#DE3700'
        />
        <path
          d='M25.3381 16.8657C24.7859 16.8657 24.3381 17.3135 24.3381 17.8657C24.3381 18.418 24.7859 18.8657 25.3381 18.8657C25.8904 18.8657 26.3381 18.418 26.3381 17.8657C26.3381 17.3135 25.8754 16.8657 25.3381 16.8657Z'
          fill='#0096FA'
        />
      </g>
      <defs>
        <clipPath id='clip0_4945_2044'>
          <rect
            width='30'
            height='20.806'
            fill='white'
            transform='translate(0.666504 0.597046)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

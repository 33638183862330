import React from 'react';

export const BarGraph = (props) => {
  const desc = props.desc ? props.desc : 'An icon of bar graph';
  return (
    <svg
      aria-labelledby='barGraphIconTitle barGraphIconDesc'
      role='img'
      viewBox='0 0 24 24'
      {...props}
    >
      <title id='barGraphIconTitle'>Bar Graph Icon</title>
      <desc id='barGraphIconDesc'>{desc}</desc>
      <path
        fill={props.fill || 'currentColor'}
        d='M22.9 0.85C22.2 0.775001 21.5 0.75 20.8 0.75C20.1 0.75 19.375 0.775001 18.675 0.85C18.075 0.900001 17.6 1.425 17.6 2.05V21.95C17.6 22.6 18.125 23.15 18.8 23.15H22.8C23.45 23.15 24 22.6 24 21.95V2.05C24 1.425 23.525 0.900001 22.9 0.85ZM14.1 6.45C13.4 6.375 12.7 6.35 12 6.35C11.3 6.35 10.575 6.375 9.875 6.45C9.275 6.5 8.8 7.025 8.8 7.65V21.95C8.8 22.6 9.325 23.15 10 23.15H14C14.65 23.15 15.2 22.6 15.2 21.95V7.65C15.2 7.025 14.725 6.5 14.1 6.45ZM5.3 12.05C4.6 11.975 3.9 11.95 3.2 11.95C2.5 11.95 1.775 11.975 1.075 12.05C0.475 12.1 0 12.625 0 13.25V21.95C0 22.6 0.525 23.15 1.2 23.15H5.2C5.85 23.15 6.4 22.6 6.4 21.95V13.25C6.4 12.625 5.925 12.1 5.3 12.05Z'
      />
    </svg>
  );
};

import React from 'react';

export const TrophyRebrand = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='41'
      height='40'
      viewBox='0 0 41 40'
      fill='none'
      {...props}
    >
      <path
        d='M14.6608 11.9104L16.5136 14.1029L16.387 16.8881C16.3697 17.259 16.5378 17.6125 16.8342 17.8344C17.0266 17.9765 17.2606 18.0528 17.4997 18.0545C17.6263 18.0545 17.7511 18.0337 17.8706 17.9904L20.7165 16.9955L23.5624 17.9904C24.1413 18.1966 24.7774 17.8933 24.9836 17.3144C25.0321 17.1775 25.0529 17.0337 25.046 16.8881L24.9195 14.1029L26.7705 11.9104C27.1674 11.4424 27.1102 10.7405 26.6423 10.3419C26.514 10.2327 26.3632 10.1547 26.202 10.1131L23.2903 9.35049L21.6282 6.99684C21.2729 6.49248 20.5761 6.37289 20.0718 6.72819C19.9678 6.80099 19.8777 6.89285 19.8031 6.99684L18.141 9.35049L15.2293 10.1131C14.6348 10.2673 14.2778 10.8757 14.432 11.4702C14.4754 11.6314 14.5533 11.7821 14.6608 11.9104ZM19.1012 11.406C19.356 11.3384 19.5778 11.1842 19.7321 10.9693L20.7165 9.57407L21.701 10.9693C21.8535 11.1842 22.0771 11.3384 22.3318 11.406L23.9246 11.822L22.935 12.9954C22.753 13.2103 22.6577 13.4859 22.6733 13.7666L22.7426 15.3386L21.0822 14.7597C20.8448 14.6748 20.5848 14.6748 20.3474 14.7597L18.6887 15.3386L18.7598 13.7666C18.7719 13.4859 18.6783 13.2103 18.4981 12.9954L17.5084 11.822L19.1012 11.406Z'
        fill='#DE3700'
      />
      <path
        d='M39.4955 3.55475C39.2685 3.32771 38.9669 3.20119 38.6463 3.20119H32.0342V2.02436C32.0359 1.36228 31.4986 0.823266 30.8348 0.821533H10.5982C9.93617 0.821533 9.39715 1.36055 9.39715 2.02436V3.20119H2.78681C2.12474 3.20119 1.58572 3.7402 1.58398 4.40401V9.83405C1.58918 14.5327 5.25485 18.344 9.94137 18.5398C10.9466 21.7999 13.3193 24.4239 16.4633 25.7533V26.7204C16.4598 29.7864 13.9623 32.2839 10.8981 32.2874H10.8946C10.2343 32.2874 9.69525 32.8246 9.69352 33.4885V37.8786C9.69352 38.5407 10.2308 39.0797 10.8981 39.0814H30.535C31.1971 39.0814 31.7343 38.5441 31.7361 37.8786V33.4885C31.7361 32.8264 31.1971 32.2891 30.5332 32.2891C27.4673 32.2856 24.9697 29.7864 24.9663 26.7221V25.755C28.1103 24.4274 30.483 21.8034 31.49 18.5415C36.1765 18.3457 39.8404 14.5327 39.8456 9.83579V4.40401C39.8456 4.08337 39.7208 3.7818 39.4955 3.55475ZM37.4417 5.6051V9.83405C37.4365 12.9607 35.1192 15.6298 32.0342 16.0804V5.6051H37.4417ZM12.0974 36.6775V34.693H29.3339V36.6775H12.0974ZM24.8346 32.2874H16.5985C18.044 30.809 18.869 28.7915 18.869 26.7204V26.4414C19.4496 26.535 20.0406 26.5835 20.6281 26.5835H20.8032C21.3907 26.5835 21.9818 26.535 22.5624 26.4414V26.7204C22.5624 28.7933 23.3891 30.809 24.8346 32.2874ZM23.4324 23.7619C23.4134 23.7671 23.3943 23.774 23.3856 23.7775C22.5502 24.0444 21.6802 24.1796 20.8032 24.1796H20.6281C19.7512 24.1796 18.8828 24.0444 18.0561 23.7809C18.037 23.7723 18.018 23.7653 18.0058 23.7619C14.25 22.4689 11.7577 18.9332 11.8028 14.9608V3.29998H29.632V14.9625C29.6753 18.9332 27.1813 22.4706 23.4324 23.7619ZM9.39715 5.6051V16.0804C6.31209 15.6281 3.99483 12.9607 3.98963 9.83405V5.6051H9.39715Z'
        fill='#0096FA'
      />
    </svg>
  );
};
